import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { getModuleAlias, getTerminal, newTerminal, updateTerminal, updatedAddAlias } from "../api"
import WaitPopup from "./WaitPopup"

interface Props {
	id: number
	handleClose: Function
	newState: boolean
}

export default function (props: Props) {
	const [terminal, setTerminal] = React.useState<any | undefined>()
	const [name, setName] = React.useState("")
	const [abbrv, setAbbrv] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [glCode, setGlCode] = React.useState("")

	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (terminal) {
			setName(terminal.name)
			setEmail(terminal.contact_email)
			setAbbrv(terminal.abbrv)
		}
	}, [terminal])

	function loadPageInfo() {
		if (props.newState) {
			setTerminal({ name: "", contact_email: "", abbrv: "" })
		} else {
			getTerminal(props.id, setTerminal)
			getModuleAlias(props.id, (data) => {
				if (data) {
					setGlCode(data.alias)
				}
			})
		}
	}

	function handleSave() {
		if (validated()) {
			const alias = { moduleID: -1, glCode: "" }
			if (props.newState) {
				terminal.name = name
				terminal.email = email
				terminal.abbrv = abbrv
				alias.glCode = glCode
				terminal.module_type = "terminal"
				newTerminal(terminal, (data) => {
					if (data) {
						alias.moduleID = data.module_id
						updatedAddAlias(alias, props.handleClose)
					}
				})
			} else {
				terminal.name = name
				terminal.email = email
				terminal.abbrv = abbrv
				alias.glCode = glCode
				alias.moduleID = props.id
				terminal.module_type = "terminal"
				updateTerminal(terminal, (data) => {
					updatedAddAlias(alias, props.handleClose)
				})
			}
		} else {
			alert("Information is invalid or in the wrong format.")
		}
	}

	function validated() {
		if (props.newState) {
			if (name && validateEmail(email)) {
				return true
			} else return false
		} else {
			if (name && validateEmail(email)) {
				return true
			}
		}
		return false
	}

	function validateEmail(email: string) {
		if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
			return true
		}
		return false
	}

	let emailError = false
	if (validateEmail(email)) {
		emailError = false
	} else {
		emailError = true
	}

	if (terminal) {
		openWait = false
	}

	return (
		<div>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Grid container>
				<Grid item sm={12}>
					<Box>{props.newState ? <h3>Add Terminal</h3> : <h3>Edit Terminal</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="NAME"
								value={name}
								onChange={(event: any) => {
									setName(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="ABBREVIATION"
								value={abbrv}
								onChange={(event: any) => {
									setAbbrv(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								error={emailError ? true : false}
								label="EMAIL"
								type="email"
								value={email}
								onChange={(event: any) => {
									setEmail(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="GL CODE"
								value={glCode}
								onChange={(event: any) => {
									setGlCode(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										props.handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary">
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
