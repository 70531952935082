export interface ccmRecons {
	id_: string
	documented_dt: Date
	dueDt: Date
	name: string
	user: string
	entered_dt: Date
}

export const HeaderDataInvoiceRecons: HeadCell[] = [
	{
		id: "documented_dt",
		numeric: false,
		disablePadding: true,
		label: "CCM #",
	},
	{ id: "dueDt", numeric: false, disablePadding: false, label: "DUE DATE" },
	{ id: "name", numeric: false, disablePadding: false, label: "FILE NAME" },
	{ id: "user", numeric: false, disablePadding: false, label: "USER" },
	{
		id: "entered_dt",
		numeric: false,
		disablePadding: false,
		label: "CCM DATE",
	},
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof ccmRecons
	label: string
	numeric: boolean
}
