import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import React from "react"
import { deleteRate, getRates } from "../../../api"
import { formatDateMMDDYYYY } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import EditRate from "../../EditRate"
import TableToExcel from "../../TableToExcel/TableToExcel"
import WaitPopup from "../../WaitPopup"
import { useStyles } from "./styles"
import { type DataRatesManager, type HeadCell, HeaderDataRatesManager } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataRatesManager) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	const createSortHandler =
		(property: keyof DataRatesManager) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				<TableCell
					style={{
						width: 10,
						padding: 0,
						margin: 0,
						backgroundColor: "rgba(4, 101, 170, 1)",
						color: "white",
					}}
				/>
				<TableCell
					style={{
						width: 10,
						padding: 0,
						margin: 0,
						backgroundColor: "rgba(4, 101, 170, 1)",
						color: "white",
					}}
				/>
			</TableRow>
		</TableHead>
	)
}

function rand() {
	return Math.round(Math.random() * 15) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

export default function (props: any) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("name")
	const [selectedItem, setSelected] = React.useState<number>()
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(200)
	const [editID, setEditID] = React.useState()
	const [open, setOpen] = React.useState(false)
	const [rates, setRates] = React.useState<any | undefined>()
	const [modalStyle] = React.useState(getModalStyle)
	const [newState, setNewState] = React.useState(false)
	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof DataRatesManager,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
		let newSelected = -1
		newSelected = name
		setSelected(newSelected)
	}

	const loadPageInfo = () => {
		getRates(setRates)
	}

	const handleOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		loadPageInfo()
		setOpen(false)
	}

	const handleEditClick = (event: any, id: any) => {
		setNewState(false)
		setEditID(id)
		setOpen(true)
	}
	const handleDeleteClick = (event: any, id: number) => {
		deleteRate(id, loadPageInfo)
	}

	const handleNewClick = (event: any) => {
		setNewState(true)
		setOpen(true)
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(Number.parseInt(event.target.value, 10))
		setPage(0)
	}

	if (rates) {
		openWait = false
	}

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={open}
				onClose={handleClose}>
				<div style={modalStyle} className={classes.paper}>
					<EditRate id={editID} handleClose={handleClose} newState={newState} />
				</div>
			</Modal>
			<Box style={{ height: "auto", width: "100%", padding: 0 }}>
				<TableToExcel
					id="tte_rates"
					sheet="Worksheet"
					table="rates"
					fileName="rateList"
					buttonText=""
					className=""
				/>
				<IconButton
					onClick={(event) => handleNewClick(event)}
					style={{ float: "right", padding: 3 }}>
					<AddIcon />
				</IconButton>
			</Box>
			<TableContainer style={{ width: "100%" }}>
				<Table id="rates" stickyHeader={true} size={"small"}>
					<SortableTableHeaders
						classes={classes}
						numSelected={1}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={0}
						headCells={HeaderDataRatesManager}
					/>
					<TableBody>
						{rates &&
							stableSort(rates, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow
											key={index}
											hover
											onClick={(event) => handleClick(event, index)}
											tabIndex={-1}
											selected={selectedItem === index}>
											<TableCell>{row.id_}</TableCell>
											<TableCell>{row.iep_abbrv}</TableCell>
											<TableCell>{row.region}</TableCell>
											<TableCell>{row.pool_name}</TableCell>
											<TableCell>{row.terminal_abbrv}</TableCell>
											<TableCell>{row.ssl_abbrv}</TableCell>
											<TableCell>{row.current_rate}</TableCell>
											<TableCell>
												{row.effective_dt_cur
													? formatDateMMDDYYYY(new Date(row.effective_dt_cur.replace(" GMT", "")))
													: null}
											</TableCell>
											<TableCell>{row.upcoming_rate}</TableCell>
											<TableCell>
												{row.effective_dt_up
													? formatDateMMDDYYYY(new Date(row.effective_dt_up.replace(" GMT", "")))
													: null}
											</TableCell>
											<TableCell>{row.previous_rate}</TableCell>
											<TableCell>
												{row.effective_date_pre
													? formatDateMMDDYYYY(new Date(row.effective_date_pre.replace(" GMT", "")))
													: null}
											</TableCell>
											<TableCell>
												<IconButton onClick={(event) => handleEditClick(event, row.id_)}>
													<EditIcon />
												</IconButton>
											</TableCell>
											<TableCell>
												<IconButton onClick={(event) => handleDeleteClick(event, row.id_)}>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									)
								})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[15]}
				component="div"
				count={rates ? rates.length : []}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleRowsPerPageChange}
			/>
		</div>
	)
}
