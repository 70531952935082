import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import DeleteIcon from "@material-ui/icons/Delete"
import WarningIcon from "@material-ui/icons/Warning"
import React from "react"
import { Link } from "react-router-dom"
import { formatDateMMDDYYYY } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type HeadCell, HeaderDataInvoiceRecons, type ccmRecons } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ccmRecons) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
	canDelete?: boolean
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	const createSortHandler = (property: keyof ccmRecons) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property)
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				{props.canDelete && (
					<TableCell
						style={{
							width: 10,
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						...
					</TableCell>
				)}
			</TableRow>
		</TableHead>
	)
}

interface Props {
	ccmData: any[]
	handleDeleteClick: Function
}

export default function (props: Props) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>(Object.entries(props.ccmData[0])[0][0])
	const [selected, setSelected] = React.useState<string[]>([])

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ccmRecons) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected: string[] = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}

		setSelected(newSelected)
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<Box width="100%">
				<TableToExcel
					table="ccmRec"
					fileName={"ccmRec_" + Date.now()}
					sheet="test1"
					buttonText="export"
					id="button-download-as-xls"
					className="button-download"
				/>
			</Box>
			<TableContainer style={{ maxHeight: "400px", width: "100%" }}>
				<Table id="ccmRec" stickyHeader={true} size={"small"}>
					<SortableTableHeaders
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={props.ccmData ? props.ccmData.length : 0}
						headCells={HeaderDataInvoiceRecons}
						canDelete={props.handleDeleteClick ? true : false}
					/>
					<TableBody style={{ height: "100%", width: "100%" }}>
						{stableSort(props.ccmData, getComparator(order, orderBy)).map((row, index) => {
							const isItemSelected = isSelected(row.id_)
							return (
								<TableRow
									key={row.id_}
									hover
									onClick={(event) => handleClick(event, row.id_)}
									tabIndex={-1}
									selected={isItemSelected}>
									<TableCell>
										<Link to={"/ccmworkflow/" + row.id_}>
											{row.documented_dt ? formatDateMMDDYYYY(new Date(row.documented_dt)) : ""}
										</Link>
									</TableCell>
									<TableCell>
										{row.dueDt ? formatDateMMDDYYYY(new Date(row.dueDt)) : ""}
										{row.isUrgent && <WarningIcon style={{ color: "red" }} />}
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.user}</TableCell>
									<TableCell>
										{row.entered_dt
											? formatDateMMDDYYYY(new Date(row.entered_dt.replace(" GMT", "")))
											: ""}
									</TableCell>
									{props.handleDeleteClick && (
										<TableCell>
											<IconButton
												aria-label="delete"
												size="small"
												onClick={() => {
													props.handleDeleteClick(row.id_)
												}}>
												<DeleteIcon fontSize="inherit" />
											</IconButton>
										</TableCell>
									)}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
