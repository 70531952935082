import Typography from "@material-ui/core/Typography"
import type * as React from "react"

interface Props {
	error: string | null | undefined
}

const ErrorMessage: React.FC<Props> = (props) => {
	if (typeof props.error === "string") {
		let error = props.error
			.trim()
			.replace(/^(API)?Error:/, "")
			.trim()

		if (error.length === 0) {
			error = "Error"
		}

		return <Typography color="error">{error}</Typography>
	}
	return null
}

export default ErrorMessage
