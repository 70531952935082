import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import EditIcon from "@material-ui/icons/Edit"
import FilterIcon from "@material-ui/icons/FilterList"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import React from "react"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import FilterDialog from "../../FilterDialogOP"
import OpenDispute from "../../OpenDisposition"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type DataDisputeWorkflow, type HeadCell, HeaderDataInvoiceRecons } from "./types"

const TriniumIcon = require("../../../img/icons/image61.png")

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataDisputeWorkflow) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	const createSortHandler =
		(property: keyof DataDisputeWorkflow) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				<TableCell
					padding={"checkbox"}
					style={{
						width: 5,
						backgroundColor: "rgba(4, 101, 170, 1)",
						color: "white",
					}}>
					{"ATCH"}
				</TableCell>
				<TableCell
					padding={"checkbox"}
					style={{
						backgroundColor: "rgba(4, 101, 170, 1)",
						color: "white",
					}}>
					{"..."}
				</TableCell>

				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

function rand() {
	return Math.round(Math.random() * 15) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50
	return {
		top: `${10}%`,
		left: `${3}%`,
		overflow: "auto",
	}
}
interface OpenDisputeProps {
	openDisputeData: any[]
	maxHeightPx?: number
}

export default function (props: OpenDisputeProps) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("id_")
	const [selectedItem, setSelected] = React.useState<number>()
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(200)
	const [modalStyle] = React.useState(getModalStyle)
	const [open, setOpen] = React.useState(false)
	const [invoiceRecordID, setinvoiceRecordID] = React.useState<number | undefined>()
	const [filtersOpen, setFiltersOpen] = React.useState(false)
	const [filteredRecords, setFilteredRecords] = React.useState<any[]>([])
	const [unfilteredRecords, setUnfilteredRecords] = React.useState<any[]>([])
	const [checkedAll, setCheckedAll] = React.useState(false)
	React.useEffect(() => {
		if (props.openDisputeData) {
			const sessionFilter = localStorage.getItem("dashboardfilter")
			const iepFilters = sessionFilter ? sessionFilter.split(",") : undefined
			let tempFilteredData = props.openDisputeData
			if (iepFilters && iepFilters.length > 0) {
				tempFilteredData = []
				props.openDisputeData.forEach((item) => {
					if (iepFilters.includes(item.iep_id.toString())) {
						tempFilteredData.push(item)
					}
				})
			}

			setUnfilteredRecords(props.openDisputeData)
			setFilteredRecords(tempFilteredData)
		}
	}, [props.openDisputeData])

	React.useEffect(() => {
		if (checkedAll && filteredRecords) {
			setRowsPerPage(filteredRecords.length)
		} else {
			setRowsPerPage(200)
		}
	}, [checkedAll])

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof DataDisputeWorkflow,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
		let newSelected = -1
		newSelected = name
		setSelected(newSelected)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleEditClick = (event: any, id: any) => {
		setinvoiceRecordID(id)
		setOpen(true)
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(Number.parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleCheckChange = (event: any) => {
		setCheckedAll(event.target.checked)
	}

	const handleFilterClick = () => {
		setFiltersOpen(true)
	}

	const maxHeightStyle = props.maxHeightPx ? props.maxHeightPx.toString() + "px" : "90%"
	return (
		<>
			<div style={{ height: "auto", width: "100%" }}>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={open}
					onClose={handleClose}>
					<div style={modalStyle} className={classes.paper}>
						<OpenDispute OnClose={handleClose} invoiceRecordID={invoiceRecordID} />
					</div>
				</Modal>
				<FilterDialog
					open={filtersOpen}
					onClose={setFiltersOpen}
					unfilteredRecords={unfilteredRecords}
					setFilteredRecords={setFilteredRecords}
				/>
				<Box style={{ height: "auto", width: "100%", padding: 0 }}>
					<FormControlLabel
						style={{ float: "right" }}
						control={
							<Checkbox checked={checkedAll} style={{ padding: 5 }} onChange={handleCheckChange} />
						}
						label="All"
					/>
					<TableToExcel
						id="tte_openDisputes"
						sheet="Worksheet"
						table="openDisputes"
						fileName={"openDisputes_" + Date.now()}
						buttonText=""
						className=""></TableToExcel>
					<Button onClick={handleFilterClick} style={{ float: "right", padding: 3 }}>
						<FilterIcon fontSize="small" />
					</Button>
				</Box>
			</div>
			<div style={{ height: "auto", width: "100%" }}>
				{filteredRecords && filteredRecords.length > 0 ? (
					<>
						<TableContainer style={{ maxHeight: maxHeightStyle, width: "100%" }}>
							<Table id="openDisputes" stickyHeader={true} size={"small"}>
								<SortableTableHeaders
									classes={classes}
									numSelected={1}
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
									rowCount={filteredRecords.length}
									headCells={HeaderDataInvoiceRecons}
								/>
								<TableBody>
									{filteredRecords &&
										stableSort(filteredRecords, getComparator(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((row, index) => {
												return (
													<TableRow
														key={row.inv_rec_id}
														hover
														onClick={(event) => handleClick(event, index)}
														tabIndex={-1}
														selected={selectedItem === index}>
														<TableCell style={{ padding: 1, width: 5 }}>
															{row.attch ? (
																<IconButton>
																	<InsertDriveFileIcon />
																</IconButton>
															) : (
																<div></div>
															)}
														</TableCell>
														<TableCell style={{ padding: 3 }}>
															<IconButton
																onClick={(event) => handleEditClick(event, row.inv_rec_id)}>
																<EditIcon />
															</IconButton>
														</TableCell>
														<TableCell>{row.inv_rec_id}</TableCell>
														<TableCell>
															{row.invoice_num ? row.invoice_num.toUpperCase() : ""}
														</TableCell>
														<TableCell>{row.chassis ? row.chassis.toUpperCase() : ""}</TableCell>
														<TableCell>
															{row.container ? row.container.toUpperCase() : ""}
														</TableCell>
														<TableCell>
															{row.invoice_area ? row.invoice_area.toUpperCase() : ""}
														</TableCell>
														<TableCell>
															{row.dispute_reason ? row.dispute_reason.toUpperCase() : ""}
														</TableCell>
														<TableCell>
															{row.invoice_ssl ? row.invoice_ssl.toUpperCase() : ""}
														</TableCell>
													</TableRow>
												)
											})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[rowsPerPage]}
							component="div"
							count={filteredRecords.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handleRowsPerPageChange}
						/>
					</>
				) : (
					<h5>NO RECORDS</h5>
				)}
			</div>
		</>
	)
}
