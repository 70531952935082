import { Button } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import clonedeep from "lodash/cloneDeep"
import React from "react"
import { useHistory } from "react-router-dom"
import { getAPIURL, getIepList, getReportParams, updateParams } from "../api"
import Layout from "../components/layout/Layout"
import { authStore } from "../stores/authStore"
import type { ReportData } from "../types/ReportData"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		wrapper: {
			width: "100%",
			height: "100%",
		},
		inputWrapper: {
			position: "absolute",
			overflow: "hidden",
			display: "inline",
			width: "100%",
			height: "50%",
			zIndex: 1,
			opacity: 0,
			left: 0,
			top: 0,
		},
		paper: {
			position: "absolute",
			width: 500,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

export default function (props: any) {
	const classes = useStyles()
	const [params, setParams] = React.useState<any | undefined>()
	const [reportID, setReportID] = React.useState<any | undefined>()
	const [reportData, setReportData] = React.useState<ReportData | undefined>()
	const inputLabel = React.useRef<HTMLLabelElement>(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	const [iepList, setIepList] = React.useState<any | undefined>([])
	const [allIeps, setAllIeps] = React.useState<any | undefined>("-1")
	const [iepSelected, setIepSelected] = React.useState<any | undefined>(-1)
	const auth = authStore.use()
	const history = useHistory()

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	const loadPageInfo = () => {
		if (props.location.state && props.location.state.reportID) {
			setReportID(props.location.state.reportID)
			setReportData({
				allColumns: undefined,
				existingReport: props.location.state.existingReport,
				reportName: props.location.state.reportName,
				isScheduled: props.location.state.isScheduled,
				isWeekly: props.location.state.isWeekly,
				category: props.location.state.category,
				scheduledTime: props.location.state.scheduledTime,
				scheduledStartDate: props.location.state.scheduledStartDate,
				weekdays: props.location.state.weekdays,
				frequency: props.location.state.frequency,
				activeColumns: undefined,
				filters: undefined,
				recipients: undefined,
				username: "",
				reportID: props.location.state.reportID,
				type_: props.location.state.type_,
			})
			getReportParams(props.location.state.reportID, (data) => {
				if (data) {
					let isIepId = false
					data.forEach((item) => {
						item.modified = false
						if (item.param_name === "@iep_id") {
							isIepId = true
							if (item.param_value.includes(",")) {
								setIepSelected(-1)
							} else {
								setIepSelected(item.param_value)
							}
						}
					})
					if (isIepId) {
						getIepList((data) => {
							if (data) {
								let combinedIeps = allIeps
								data.forEach((element) => {
									combinedIeps += "," + String(element.id_)
								})
								setAllIeps(combinedIeps)
							}
							setIepList(data)
						})
					}
					setParams(data)
				}
			})
		}
		if (inputLabel && inputLabel.current) {
			setLabelWidth(inputLabel.current!.offsetWidth)
		}
	}

	const handleTextChange = (event: any, paramID: number) => {
		if (event.target.value != "") {
			const tempParams = clonedeep(params)
			tempParams.forEach((param) => {
				if (param.id_ == paramID) {
					param.param_value = event.target.value
					param.modified = true
				}
			})
			setParams(tempParams)
		}
	}

	const handleParamSave = () => {
		updateParams(reportID, params, () => {
			handleDownloadClick(reportID, reportData.reportName)
			history.push("/reports")
		})
	}

	const handleDownloadClick = (reportID, reportName) => {
		const doclink = document.createElement("a")
		doclink.href =
			getAPIURL() + "export/report/" + reportID + "/" + Math.floor(Math.random() * 100000)
		doclink.target = "blank"
		doclink.click()
	}

	const handleIEPDDChange = (event: any) => {
		const tempParams = clonedeep(params)
		tempParams.forEach((param) => {
			if (param.param_name == "@iep_id") {
				if (event.target.value === -1) {
					param.param_value = allIeps
				} else {
					param.param_value = String(event.target.value)
				}
				param.modified = true
			}
		})
		setParams(tempParams)
		setIepSelected(String(event.target.value))
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<Box style={{ padding: 30 }}>
					<Grid
						container
						style={{
							width: "auto",
							margin: "0% 20%",
						}}>
						<Grid item sm={12} xs={12} style={{ padding: 30 }}>
							<Box justifyContent="center" display="flex">
								Modify Parameters
							</Box>
						</Grid>
						{params &&
							params.map((param) => {
								if (param.param_name === "@iep_id") {
									return (
										<Grid
											key={param.id_}
											item
											sm={12}
											style={{ textAlign: "center", paddingBottom: 20 }}>
											<FormControl variant="outlined" style={{ width: "100%" }}>
												<InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
													Select IEP
												</InputLabel>
												<Select
													labelId="demo-simple-select-outlined-label"
													id="demo-simple-select-outlined"
													onChange={handleIEPDDChange}
													labelWidth={labelWidth}
													value={iepSelected}>
													<MenuItem value={-1}>ALL IEPs</MenuItem>
													{iepList &&
														iepList.map((i: any) => {
															return <MenuItem value={i.id_}>{i.abbrv}</MenuItem>
														})}
												</Select>
											</FormControl>
										</Grid>
									)
								} else {
									return (
										<Grid
											key={param.id_}
											item
											sm={12}
											style={{ textAlign: "center", paddingBottom: 20 }}>
											<TextField
												id="standard-basic"
												variant="outlined"
												label={param.param_name}
												defaultValue={param.param_value}
												onChange={(event) => {
													handleTextChange(event, param.id_)
												}}
											/>
										</Grid>
									)
								}
							})}

						<Grid item sm={12}>
							<Box justifyContent="center" display="flex" style={{ margin: 30 }}>
								<Grid container direction="row" alignItems="center">
									<Grid item sm={3}>
										<Button
											variant="outlined"
											color="primary"
											style={{ margin: 10, float: "left", width: "80%" }}
											onClick={(e) => {
												history.push("/reportdetails", reportData)
											}}>
											Back
										</Button>
									</Grid>
									<Grid item sm={6}>
										<hr color="#909090" />
									</Grid>
									<Grid item sm={3}>
										<Button
											variant="contained"
											color="primary"
											style={{ margin: 10, float: "right", width: "80%" }}
											onClick={handleParamSave}>
											Save and Download
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
