import * as api from "../api"

import * as userAPI from "../api/userAPI"
import type { Me } from "../types/Me"
import { Store } from "./Store"

interface State {
	isWaitingForAuth: boolean
	me?: Me | null
}

class AuthStore extends Store<State> {
	private setMe(me: Me | null) {
		this.setState({
			isWaitingForAuth: false,
			me,
		})
	}

	async initialLoad() {
		try {
			const me = await userAPI.getMe()
			this.setMe(me)
		} catch (err) {
			this.setMe(null)
		}
	}

	async signIn(email: string, password: string) {
		const me = await userAPI.signIn(email, password)
		this.setMe(me)
	}

	forgotPassword(email: string): Promise<void> {
		return api.forgotPassword(email)
	}

	async resetPassword(newPassword: string, token: string) {
		const me = await api.resetPassword(newPassword, token)
		this.setMe(me)
	}

	async signOut() {
		try {
			await userAPI.signOut()
		} catch (err) {
		} finally {
			this.clearMe()

			document.location.pathname = ""
			document.location.href = document.location.origin
		}
		setTimeout(() => {
			this.clearMe()
			document.location.reload()
		}, 1000)
	}

	clearMe() {
		this.setMe(null)
		document.cookie = "uwl-auth-token" + "=; Max-Age=-99999999;"
	}
}

export const authStore = new AuthStore({
	isWaitingForAuth: true,
	me: null,
})
