export interface DataApHistoryDetails {
	id_: number
	container1: string
	chassis: string
	total_billed: number
}

export const HeaderDataApSums: HeadCell[] = [
	{ id: "id_", numeric: false, disablePadding: false, label: "RECORD ID" },
	{ id: "chassis", numeric: false, disablePadding: false, label: "CHASSIS" },
	{ id: "container1", numeric: false, disablePadding: false, label: "CONTAINER" },
	{ id: "total_billed", numeric: false, disablePadding: false, label: "TOTAL BILLED" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataApHistoryDetails
	label: string
	numeric: boolean
}
