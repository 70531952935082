export interface DataCustomerManager {
	id_: number
	name: string
	contact_email: string
	customer_code: string
	is_nam: string
	alternate_email: string
}

export const HeaderDataCustomerManager: HeadCell[] = [
	{
		id: "name",
		numeric: false,
		disablePadding: false,
		label: "NAME",
	},
	{ id: "customer_code", numeric: false, disablePadding: false, label: "ABBREVIATION" },
	{ id: "contact_email", numeric: false, disablePadding: false, label: "EMAIL" },
	{ id: "is_nam", numeric: false, disablePadding: false, label: "IS NAM?" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataCustomerManager
	label: string
	numeric: boolean
}
