import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { getDisputeReason, newDisputeReason, updateDisputeReason } from "../api"

interface Props {
	id: number
	handleClose: Function
	newState: boolean
}

export default function (props: Props) {
	const [responseText, setResponseText] = React.useState("")
	const [reason, setReason] = React.useState<any | undefined>()

	React.useEffect(() => {
		if (!props.newState && props.id) {
			getDisputeReason(props.id, setReason)
		}
	}, [])

	React.useEffect(() => {
		if (reason) {
			setResponseText(reason.reason)
		}
	}, [reason])

	function handleSave() {
		if (responseText) {
			if (!props.newState && props.id) {
				updateDisputeReason(
					{ id_: props.id, reason: responseText.toUpperCase() },
					props.handleClose,
				)
			} else {
				newDisputeReason(responseText.toUpperCase(), props.handleClose)
			}
		}
	}

	return (
		<div>
			<Grid container style={{ backgroundColor: "white" }}>
				<Grid item sm={12} style={{ margin: 10 }}>
					<Box>
						{!props.newState && props.id ? (
							<h3>Update Canned Response</h3>
						) : (
							<h3>Add New Canned Response</h3>
						)}
					</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={12} style={{ margin: 10 }}>
							<TextField
								label="Response Text"
								fullWidth
								value={responseText}
								onChange={(event: any) => {
									setResponseText(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid item sm={12}>
						<Box style={{ float: "right" }}>
							<Button
								style={{ margin: 5 }}
								onClick={() => {
									props.handleClose()
								}}
								variant="outlined"
								color="primary">
								Close
							</Button>
							<Button style={{ margin: 5 }} onClick={handleSave} variant="outlined" color="primary">
								Save
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
