import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import DeleteIcon from "@material-ui/icons/Delete"
import FilterIcon from "@material-ui/icons/FilterList"
import React from "react"
import { Link } from "react-router-dom"
import { formatCurrency } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import FilterDialog from "../../FilterDialogAP"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type DataAPInvoices, type HeadCell, HeaderDataAPInvoices } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataAPInvoices) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
	canDelete?: boolean
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	const createSortHandler =
		(property: keyof DataAPInvoices) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				{props.canDelete && (
					<TableCell
						style={{
							width: 10,
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						...
					</TableCell>
				)}
			</TableRow>
		</TableHead>
	)
}
interface Props {
	invoiceData: any[]
	handleDeleteClick?: Function
	maxHeight?: number
}

export default function (props: Props) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("id_")
	const [selected, setSelected] = React.useState<string[]>([])
	const [filtersOpen, setFiltersOpen] = React.useState(false)
	const [filteredRecords, setFilteredRecords] = React.useState<any[]>([])
	const [unfilteredRecords, setUnfilteredRecords] = React.useState<any[]>([])
	const [filterOn, setFilterOn] = React.useState<any | undefined>(false)
	const height = props.maxHeight ? props.maxHeight : 400

	React.useEffect(() => {
		if (props.invoiceData) {
			props.invoiceData.forEach((item) => {
				item.reconciliation =
					Math.round((item.total_billed + Number.EPSILON) * 100) / 100 -
					Math.round((item.credits + item.past_payments + Number.EPSILON) * 100) / 100
			})
			const sessionFilter = localStorage.getItem("dashboardfilter")
			const iepFilters = sessionFilter ? sessionFilter.split(",") : undefined
			let tempFilteredData = props.invoiceData
			if (iepFilters && iepFilters.length > 0) {
				tempFilteredData = []
				props.invoiceData.forEach((item) => {
					if (iepFilters.includes(item.iep_id.toString())) {
						tempFilteredData.push(item)
					}
				})
			}

			setUnfilteredRecords(props.invoiceData)
			setFilteredRecords(tempFilteredData)
		}
	}, [props.invoiceData])

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DataAPInvoices) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected: string[] = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}

		setSelected(newSelected)
	}

	const handleFilterClick = () => {
		setFiltersOpen(true)
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1
	const viewCredits = (row: any) => {
		return row.credits
	}
	return (
		<div style={{ height: "100%", width: "100%" }}>
			<FilterDialog
				open={filtersOpen}
				onClose={setFiltersOpen}
				unfilteredRecords={unfilteredRecords}
				setFilteredRecords={setFilteredRecords}
				setFilterOn={setFilterOn}
			/>
			<Box width="100%">
				<TableToExcel
					table="InvoiceRec"
					fileName={"InvoiceRec_" + Date.now()}
					sheet="test1"
					buttonText="export"
					id="button-download-as-xls"
					className="button-download"
				/>
				<Button onClick={handleFilterClick} style={{ float: "right" }}>
					<FilterIcon fontSize="small" />
				</Button>
			</Box>

			<TableContainer style={{ maxHeight: height, width: "100%" }}>
				<Table id="InvoiceRec" stickyHeader={true} size={"small"}>
					<SortableTableHeaders
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={filteredRecords.length}
						headCells={HeaderDataAPInvoices}
						canDelete={props.handleDeleteClick ? true : false}
					/>
					<TableBody>
						{stableSort(filteredRecords, getComparator(order, orderBy)).map((row, index) => {
							const isItemSelected = isSelected(row.id_)
							viewCredits(row)
							if (row.total_billed - row.credits - row.past_payments > 0 || filterOn) {
								return (
									<TableRow
										key={row.id_}
										hover
										onClick={(event) => handleClick(event, row.id_)}
										tabIndex={-1}
										selected={isItemSelected}>
										<TableCell>
											<Link to={"/apworkflow/" + row.invoice_id}>
												{row.invoice_num ? row.invoice_num.toUpperCase() : ""}
											</Link>
										</TableCell>
										<TableCell>
											{row.n_days_old}
											{row.n_days_old > 1 ? " Days" : " Day"}
										</TableCell>
										<TableCell>
											${row.total_billed ? formatCurrency(row.total_billed) : 0}
										</TableCell>
										<TableCell>
											${row.pending_payments ? formatCurrency(row.pending_payments) : 0}
										</TableCell>
										<TableCell>
											${row.past_payments ? formatCurrency(row.past_payments) : 0}
										</TableCell>
										<TableCell>${row.disputed ? formatCurrency(row.disputed) : 0}</TableCell>
										<TableCell>${row.credits ? formatCurrency(row.credits) : 0}</TableCell>
										<TableCell>${formatCurrency(row.reconciliation)}</TableCell>
										{props.handleDeleteClick && (
											<TableCell>
												<IconButton
													aria-label="delete"
													size="small"
													onClick={() => {
														props.handleDeleteClick(row.id_, true)
													}}>
													<DeleteIcon fontSize="inherit" />
												</IconButton>
											</TableCell>
										)}
									</TableRow>
								)
							}
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
