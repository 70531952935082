export interface DataRatesManager {
	id_: number
	iep_abbrv: string
	region: string
	pool_name: string
	terminal_abbrv: string
	ssl_abbrv: string
	current_rate: string
	effectiv_dt_cur: string
	upcoming_rate: string
	effectiv_dt_up: string
	previous_rate: string
	effective_date_pre: string
}

export const HeaderDataRatesManager: HeadCell[] = [
	{ id: "id_", numeric: false, disablePadding: false, label: "ID" },
	{
		id: "iep_abbrv",
		numeric: false,
		disablePadding: false,
		label: "PROVIDER",
	},

	{ id: "region", numeric: false, disablePadding: false, label: "REGION" },
	{ id: "pool_name", numeric: false, disablePadding: false, label: "POOL" },
	{ id: "terminal_abbrv", numeric: false, disablePadding: false, label: "TERMINAL" },
	{ id: "ssl_abbrv", numeric: false, disablePadding: false, label: "SSL" },
	{ id: "current_rate", numeric: false, disablePadding: false, label: "CURRENT RATE" },
	{ id: "effectiv_dt_cur", numeric: false, disablePadding: false, label: "EFFECTIVE DATE" },
	{ id: "upcoming_rate", numeric: false, disablePadding: false, label: "UPCOMING RATE" },
	{ id: "effectiv_dt_up", numeric: false, disablePadding: false, label: "EFFECTIVE DATE" },
	{ id: "previous_rate", numeric: false, disablePadding: false, label: "PREVIOUS RATE" },
	{ id: "effective_date_pre", numeric: false, disablePadding: false, label: "EFFECTIVE DATE" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataRatesManager
	label: string
	numeric: boolean
}
