import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import FilterIcon from "@material-ui/icons/FilterList"
import React from "react"
import { Link } from "react-router-dom"
import { getInvoiceRecords } from "../api"
import TermInvoiceApproval from "../components/CustomTable/terminalInvoiceApprovalTable/TermInvoiceApproval"
import FilterDialog from "../components/FilterDialog"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { authStore } from "../stores/authStore"
import type { ApprovalRecord } from "../types/RecordApproval"

const moment = require("moment")

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: 900,
			maxHeight: 700,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

export default function (props: any) {
	const classes = useStyles()
	const [openWait, setOpenWait] = React.useState<boolean>(true)
	const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info")
	const [recordsToApproveResponse, setRecordsToApproveResponse] = React.useState<any | undefined>()
	const [recordsToApproveResponseReply, setRecordsToApproveResponseReply] = React.useState<
		any | undefined
	>()
	const [recordsToApproveAll, setRecordsToApproveAll] = React.useState<any | undefined>()
	const [filtersOpen, setFiltersOpen] = React.useState(false)
	const [filteredRecords, setFilteredRecords] = React.useState<ApprovalRecord[]>([])
	const [invoiceNum, setInvoiceNum] = React.useState<any | undefined>()

	const auth = authStore.use()
	const me = auth.me
	const invoiceID = props.match.params.id
	let allRecords: any[] = []

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (recordsToApproveResponse && recordsToApproveResponseReply) {
			let allRecordsToApprove: any = []

			if (recordsToApproveResponse && recordsToApproveResponse.length > 0) {
				allRecordsToApprove = recordsToApproveResponse
			}

			if (recordsToApproveResponseReply && recordsToApproveResponseReply.length > 0) {
				allRecordsToApprove = allRecordsToApprove.concat(recordsToApproveResponseReply)
			}

			if (allRecordsToApprove) {
				let invoiceNum = ""
				allRecordsToApprove.forEach((element) => {
					if (invoiceNum == "") {
						invoiceNum = element.invoice_num
					}
					if (element.terminal_status == 8 || element.terminal_status == 10) {
						element.selection = "reject"
					} else {
						element.selection = undefined
					}
					if (element.matches) {
						let totalRebill = 0
						element.matches.forEach((item) => {
							if (item.ar_chuse && item.ar_chuse > 0) {
								totalRebill += Number.parseFloat(item.ar_chuse)
							} else if (item.ar_trx && item.ar_trx > 0) {
								totalRebill += Number.parseFloat(item.ar_trx)
							}
							if (item.customer_name && item.full_manifest == element.manifest_num) {
								element.customer_name = item.customer_name
							}
						})
						if (element.total_billed) {
							element.profitLost = totalRebill - element.total_billed
						}
					} else {
						element.profitLost = 0
					}
					element.comment = undefined
					element.file = undefined
					if (element.dt_in) {
						element.dt_in = moment.utc(element.dt_in)
					}
					if (element.dt_out) {
						element.dt_out = moment.utc(element.dt_out)
					}
				})
				setInvoiceNum(invoiceNum)
				setRecordsToApproveAll(allRecordsToApprove)
				setFilteredRecords(allRecordsToApprove)
			}
			setOpenWait(false)
		}
	}, [recordsToApproveResponse, recordsToApproveResponseReply])

	const handleFilterClick = () => {
		setFiltersOpen(true)
	}

	const loadPageInfo = () => {
		setOpenWait(true)
		getInvoiceRecords(
			invoiceID,
			(data) => {
				if (allRecords.length > 0) {
					allRecords.concat(data)
				} else {
					allRecords = data
				}
				setRecordsToApproveResponse(data)
			},
			null,
			"TERMINALSENT",
			me.terminal_id,
		)

		getInvoiceRecords(
			invoiceID,
			(data) => {
				if (allRecords.length > 0) {
					allRecords.concat(data)
				} else {
					allRecords = data
				}
				setRecordsToApproveResponseReply(data)
			},
			null,
			"TERMINALREPLY",
			me.terminal_id,
		)
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<WaitPopup open={openWait}>{waitMsg} </WaitPopup>
				<FilterDialog
					open={filtersOpen}
					onClose={setFiltersOpen}
					unfilteredRecords={recordsToApproveAll}
					setFilteredRecords={setFilteredRecords}
				/>
				<Box style={{ padding: 30 }}>
					<Grid container style={{ overflow: "hidden", width: "100%" }}>
						<Grid item sm={10}>
							<Box>
								<h3>INVOICE # {invoiceNum}</h3>
							</Box>
						</Grid>
						<Grid item sm={2} style={{ margin: "auto" }}>
							<Button onClick={handleFilterClick} style={{ float: "right" }}>
								<FilterIcon fontSize="small" />
							</Button>
						</Grid>
						<Grid item sm={12}>
							<TermInvoiceApproval
								data={filteredRecords}
								handleDataChange={setRecordsToApproveAll}></TermInvoiceApproval>
						</Grid>
						<Grid item sm={12}>
							<Link to={"/terminal"}>
								<Button variant="outlined" color="primary" style={{ margin: 10, float: "right" }}>
									Back
								</Button>
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
