import Modal from "@material-ui/core/Modal"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			maxWidth: 800,
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			overflow: "auto",
		},
		modal: {
			margin: "auto",
			width: "50%",
			height: "95%%",
			padding: "10px",
		},
	}),
)

function rand() {
	return Math.round(Math.random() * 15) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()
	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

interface Props {
	open: boolean
	text?: string
	closeOnRequest?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void
	children?: React.ReactNode
}

export default function (props: Props) {
	const classes = useStyles()
	const [modalStyle] = React.useState(getModalStyle)
	const [openLocal, setOpenLocal] = React.useState(false)

	React.useEffect(() => {
		setOpenLocal(props.open)
	}, [props.open])

	const handleClose = () => {
		setOpenLocal(false)
	}

	if (props.closeOnRequest) {
		return (
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={props.open}
				onClose={props.closeOnRequest}
				className={classes.modal}>
				<div style={modalStyle} className={classes.paper}>
					{props.children}
				</div>
			</Modal>
		)
	} else {
		return (
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={props.open}
				className={classes.modal}>
				<div style={modalStyle} className={classes.paper}>
					{props.children}
				</div>
			</Modal>
		)
	}
}
