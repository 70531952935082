export interface DataUserManager {
	id_: number
	name: string
	email: string
	role: string
	group: string
	username: string
}

export const HeaderDataUserManager: HeadCell[] = [
	{
		id: "name",
		numeric: false,
		disablePadding: false,
		label: "NAME",
	},
	{ id: "username", numeric: false, disablePadding: false, label: "USERNAME" },
	{ id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
	{ id: "role", numeric: false, disablePadding: false, label: "ROLE" },
	{
		id: "group",
		numeric: false,
		disablePadding: false,
		label: "GROUP",
	},
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataUserManager
	label: string
	numeric: boolean
}
