import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import { getAPIURL } from "../api"
import PoolManager from "../components/CustomTable/poolManagerTable/PoolManager"
import Layout from "../components/layout/Layout"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: 900,
			maxHeight: 700,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

export default function (props: any) {
	const classes = useStyles()

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	function loadPageInfo() {}

	function handleDownloadClick() {
		const doclink = document.createElement("a")
		doclink.href = getAPIURL() + "export/pools/" + Math.floor(Math.random() * 100000)
		doclink.target = "blank"
		doclink.click()
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<Box style={{ padding: 30 }}>
					<Grid container style={{ overflow: "hidden", width: "100%" }}>
						<Grid item sm={12}>
							<Grid container>
								<Grid item sm={9}>
									<Box>
										<h3>MANAGE POOLS</h3>
									</Box>
								</Grid>
								<Grid item sm={3}>
									<Button
										size="small"
										variant="outlined"
										color="primary"
										onClick={handleDownloadClick}>
										DOWNLOAD ALL
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={12}>
							<PoolManager />
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
