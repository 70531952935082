import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import {
	getInvoiceRecordWithDisputes,
	getLatestDisputes,
	updateOrCreateDispute,
	updatedTerminalStatus,
} from "../api"
import { formatDateMMDDYYYY } from "../lib/formatters"
import { authStore } from "../stores/authStore"
import InvRecSupFiles from "./InvRecSupportFiles"
import WaitPopup from "./WaitPopup"
import UploadComp from "./upload/UploadComponent"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: 900,
			maxHeight: 700,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		check: {
			color: "rgba(65, 150, 68, 1)",
		},
		clear: {
			color: "rgba(245, 84, 44, 1)",
		},
		panel: {
			backgroundColor: "rgba(235, 235, 235, 1)",
			width: "100%",
		},
	}),
)

export default function (props: any) {
	const classes = useStyles()
	const [state, setState] = React.useState(false)
	const [amount, setAmount] = React.useState(0)
	const [file, setFile] = React.useState<any>()
	const [invoiceWithDisputes, setInvoiceWithDisputes] = React.useState<any | undefined>()
	const [comments, setComments] = React.useState<any | undefined>()
	const [internalComments, setInternalComments] = React.useState<string>("")
	const [addInternalComments, setAddInternalComments] = React.useState<string | undefined>()
	const [addedComment, setAddedComment] = React.useState<any | undefined>()
	const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info")
	const [openWait, setOpenWait] = React.useState<boolean>(true)
	const [latestDispute, setLatestDispute] = React.useState<any | undefined>()
	const [createdDispute, setCreatedDispute] = React.useState<any | undefined>()
	const [terminalStatus, setTerminalStatus] = React.useState<any | undefined>()
	const invoiceRecID = props.invoiceRecordID
	const auth = authStore.use()
	const me = auth.me

	React.useEffect(() => {
		if (invoiceRecID) {
			loadPageInfo()
		}
	}, [])

	React.useEffect(() => {
		if (comments && comments.length > 0) {
			const internal = []

			comments.forEach((element) => {
				element.created_dt = new Date(element.created_dt.replace(" GMT", ""))
			})

			comments.sort((a: any, b: any) => {
				return b.created_dt - a.created_dt
			})
			comments.forEach((element) => {
				if (element.is_internal_comment) {
					internal.push(element)
				}
			})
			formatComments(internal, setInternalComments)
		}
	}, [comments])

	React.useEffect(() => {
		if (createdDispute) {
			setOpenWait(false)
			loadPageInfo()
		}
	}, [createdDispute])

	React.useEffect(() => {
		if (file) {
			props.handleAddedFile(file, invoiceRecID)
		}
	}, [file])

	React.useEffect(() => {
		if (addedComment) {
			const disputeObj: any = {}
			disputeObj.username = me.username
			disputeObj.invoiceRecordID = invoiceRecID
			disputeObj.disputedAmt = amount
			disputeObj.paidResolved = state
			setWaitMsg("...Updating Dispute")
			setOpenWait(true)
			updateOrCreateDispute(disputeObj, setCreatedDispute)
		}
	}, [addedComment])

	React.useEffect(() => {
		setOpenWait(false)
	}, [])

	React.useEffect(() => {
		if (invoiceWithDisputes && invoiceWithDisputes.comments) {
			setComments(invoiceWithDisputes.comments)
		}
		setOpenWait(false)
	}, [latestDispute, invoiceWithDisputes])

	const formatComments = (listOfComments: any[], callback: Function) => {
		let commentsStr = ""
		listOfComments.forEach((comm) => {
			commentsStr += "[" + formatDateMMDDYYYY(comm.created_dt) + "]:\n"
			commentsStr += comm.comment + "\n"
		})
		callback(commentsStr)
	}

	const loadPageInfo = () => {
		getInvoiceRecordWithDisputes(invoiceRecID, setInvoiceWithDisputes)
		getLatestDisputes(invoiceRecID).then((res) => setLatestDispute(res))
	}

	const handleInternalTextChange = (event: any) => {
		setAddInternalComments(event.target.value)
		props.handleRecordComment(event, invoiceRecID)
	}

	const handleReplyClick = () => {
		if (invoiceWithDisputes.matches) {
			const sendToTerminalObj: any = {}
			sendToTerminalObj.invoice_record_id = invoiceRecID
			sendToTerminalObj.username = me.username
			updatedTerminalStatus(sendToTerminalObj, setTerminalStatus)
		}
	}

	return (
		<Box className={classes.panel}>
			<WaitPopup open={openWait}>{waitMsg}</WaitPopup>
			<Grid
				container
				style={{
					overflow: "hidden",
					width: "100%",
					padding: 10,
				}}>
				<Grid item sm={5}>
					<Grid container>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<Box style={{ minHeight: 20 }}>Internal Comments</Box>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<TextField
								id="outlined-multiline-static"
								disabled={true}
								multiline
								rows="4"
								defaultValue="Default Value"
								variant="outlined"
								value={internalComments}
								style={{ backgroundColor: "white", width: "90%" }}
							/>
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<TextField
								id="outlined-multiline-static"
								label="Add comment"
								multiline
								rows="2"
								variant="outlined"
								value={addInternalComments}
								onChange={handleInternalTextChange}
								style={{ backgroundColor: "white", width: "90%" }}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={5}>
					<Grid container>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							<UploadComp setFile={setFile} style={{ width: "90%" }} />
						</Grid>
						<Grid item sm={12} style={{ paddingBottom: 10 }}>
							{"Supporting Documents"}
							<InvRecSupFiles invoiceRecID={invoiceRecID} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}
