export interface DataCCMWorkflow {
	id_: number
	chassis_id: string
	container_id: string
	move_type: string
	cms_move_dt: string
	pool: string
	current_rp: string
	new_rp: string
	current_uu: string
	current_booking: string
	manifest_num: string
	current_bol: string
	customer_name: string
	site: string
	ssl: string
	booking_bol: string
}

export const HeaderDataInvoiceRecons: HeadCell[] = [
	{
		id: "new_rp",
		numeric: false,
		disablePadding: false,
		label: "NEW RP",
	},
	{ id: "id_", numeric: true, disablePadding: false, label: "ID" },
	{ id: "chassis_id", numeric: false, disablePadding: false, label: "CHASSIS" },
	{
		id: "container_id",
		numeric: false,
		disablePadding: false,
		label: "CONTAINER",
	},
	{
		id: "move_type",
		numeric: false,
		disablePadding: false,
		label: "MOVE TYPE",
	},
	{
		id: "cms_move_dt",
		numeric: false,
		disablePadding: false,
		label: "CMS MOVE Date",
	},
	{
		id: "pool",
		numeric: false,
		disablePadding: false,
		label: "POOL",
	},
	{
		id: "current_rp",
		numeric: false,
		disablePadding: false,
		label: "CURRENT RP",
	},

	{
		id: "current_uu",
		numeric: false,
		disablePadding: false,
		label: "CURRENT UU",
	},
	{
		id: "current_booking",
		numeric: false,
		disablePadding: false,
		label: "CURRENT BOOKING",
	},

	{
		id: "current_bol",
		numeric: false,
		disablePadding: false,
		label: "CURRENT BOL",
	},
	{
		id: "manifest_num",
		numeric: false,
		disablePadding: false,
		label: "MANIFEST",
	},
	{ id: "customer_name", numeric: false, disablePadding: false, label: "CUSTOMER" },
	{ id: "site", numeric: false, disablePadding: false, label: "SITE" },
	{ id: "ssl", numeric: false, disablePadding: false, label: "SSL" },
	{ id: "booking_bol", numeric: false, disablePadding: false, label: "BOOKING" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataCCMWorkflow
	label: string
	numeric: boolean
}
