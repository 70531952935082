import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Modal from "@material-ui/core/Modal"
import Paper from "@material-ui/core/Paper"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Typography from "@material-ui/core/Typography"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import {
	createDisputeHistory,
	getInvoice,
	getSettings,
	getSimpleInvoiceRecords,
	getStatusListByCategory,
	post,
	unlockInvoice,
} from "../api"
import { getDisputeReasons } from "../api/disputeReasonAPI"
import { getInvoiceReconciliationsByID } from "../api/invoiceReconciliationsAPI"
import { descendingComparator } from "../lib/sort"
import InvoiceNeedsReview from "../components/CustomTable/invoiceWorkflowTable/InvoiceNeedsReview"
import InvRecFilter from "../components/InvRecFilter"
import ApprovCriteria from "../components/InvoiceRecordApprovalCriteria"
import InvoiceRecordDisputeDetails from "../components/InvoiceRecordDisputeDetails"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { formatDateMMDDYYYY } from "../lib/formatters"
import { authStore } from "../stores/authStore"
import type { InvoiceDTO } from "../types/Invoice"
import type { DisputeReasonDTO } from "../types/tables/DisputeReason"
import type { IEPInvoicePlus, getInvoiceReconciliationsDTO } from "../types/tables/IEPRecord"
import type { RecordStatusDTO } from "../types/tables/RecordStatus"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		modal: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		button: {
			margin: theme.spacing(1),
		},
	}),
)

async function validateBulkRecords(invRecIds, daysOffset, useBolSsl, callBack, doneCallback) {
	if (invRecIds) {
		let res = await post(
			"invoice/records/validate/" + daysOffset + "/" + useBolSsl + "/1",
			{ recIDs: invRecIds },
			false,
		)
		while (res && res.recIDs && res.recIDs.length > 0) {
			callBack("...Validating Invoice Records: " + res.recIDs.length)
			res = await post(
				"invoice/records/validate/" + daysOffset + "/" + useBolSsl + "/1",
				{ recIDs: res.recIDs },
				false,
			)
		}
		doneCallback()
	}
}

function rand() {
	return Math.round(Math.random() * 10) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()

	return {
		top: `${10}%`,
		left: `${3}%`,
		overflow: "auto",
	}
}

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && <Box paddingTop={0}>{children}</Box>}
		</Typography>
	)
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	}
}

export default function InvoiceReconciliationsPage() {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const [invoiceReconciliations, setInvoiceReconciliations] =
		React.useState<getInvoiceReconciliationsDTO[]>()

	const pendingRecsRef = React.useRef<IEPInvoicePlus[]>(null)
	const [pendingFilteredRecs, setPendingFilteredRecs] = React.useState<IEPInvoicePlus[]>()

	const approvedRecsRef = React.useRef<IEPInvoicePlus[]>(null)
	const [approvedFilteredRecs, setApprovedFilteredRecs] = React.useState<IEPInvoicePlus[]>()

	const dispCreditRecsRef = React.useRef<IEPInvoicePlus[]>(null)

	const [disputedRecs, setDisputedRecs] = React.useState<IEPInvoicePlus[]>()
	const [disputedFilteredRecs, setDisputedFilteredRecs] = React.useState<IEPInvoicePlus[]>()

	const [creditedRecs, setCreditedRecs] = React.useState<IEPInvoicePlus[]>()
	const terminalRejectedRef = React.useRef<IEPInvoicePlus[]>([])

	const [invoice, setInvoice] = React.useState<InvoiceDTO>()
	const [statusList, setStatusList] = React.useState<RecordStatusDTO[]>()
	const [disputeReasons, setDisputeReasons] = React.useState<DisputeReasonDTO[]>()
	const [searchedRecId, setSearchedRecId] = React.useState<string>()
	const { id: invoiceID } = useParams<{ id: string }>()
	const [openWait, setOpenWait] = React.useState<boolean>(true)
	const [textWhileWait, setTextWhileWait] = React.useState("...Loading Info")
	const [openRecSearch, setOpenRecSearch] = React.useState<boolean>(false)
	const [searchText, setSearchText] = React.useState<string>("")
	const [resetSearch, setResetSearch] = React.useState<string>("")
	const auth = authStore.use()
	const me = auth.me
	const history = useHistory()

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		const invoiceReconciliationsApproved = (invoiceReconciliations || []).filter(
			(item) => item.record_status_name === "APPROVED",
		)
		const approvedRecsPluss = addPropertiesToArray(invoiceReconciliationsApproved)
		approvedRecsRef.current = approvedRecsPluss
		setApprovedFilteredRecs(approvedRecsPluss)

		// Invoice Reconciliations Disputed
		const invoiceReconciliationsCredited = (invoiceReconciliations || []).filter(
			(item) => item.record_status_name === "DISPUTED",
		)
		const dispCreditRecsPluss = addPropertiesToArray(invoiceReconciliationsCredited)
		dispCreditRecsRef.current = dispCreditRecsPluss
		setDisputedAndCredited(dispCreditRecsPluss)

		// Pending Recs
		const invoiceReconciliationsPending = (invoiceReconciliations || []).filter(
			(item) => item.record_status_name === "REVIEW",
		)
		const pendingRecsPluss = addPropertiesToArray(invoiceReconciliationsPending)
		pendingRecsRef.current = pendingRecsPluss
		setPendingFilteredRecs(pendingRecsPluss)

		// Terminal Rejected Recs
		const invoiceReconciliationsRejected = (invoiceReconciliations || []).filter(
			(item) => item.record_status_name === "DISAPPROVED",
		)
		terminalRejectedRef.current = addPropertiesToTerminalRejected(invoiceReconciliationsRejected)

		// otherwise
		if (invoiceReconciliations) setOpenWait(false)
		if (localStorage.getItem("invoiceWorkflowRecID")) {
			setSearchedRecId(localStorage.getItem("invoiceWorkflowRecID"))
		}
	}, [invoiceReconciliations])

	const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)

		setOpenWait(true)
		loadPageInfo()
	}
	const [modalStyle] = React.useState(getModalStyle)

	const handleRecSearchClose = () => {
		setOpenRecSearch(false)
	}

	const handleReValidate = () => {
		setOpenWait(true)
		setTextWhileWait("...Validating Invoice Records")
		getSimpleInvoiceRecords(invoiceID, (data2) => {
			if (data2) {
				setTextWhileWait("...Validating Invoice Records: " + data2.length)
				let settings = undefined
				let daysOffset = undefined
				let useBolSsl = undefined

				getSettings((data) => {
					settings = data
					if (data) {
						data.forEach((item) => {
							if (item.name == "DaysOffsetForValidate") {
								daysOffset = item.value
							}
							if (item.name == "UseBolSslForValidate") {
								useBolSsl = item.value
							}
						})
						validateBulkRecords(data2, daysOffset, useBolSsl, setTextWhileWait, () => {
							setTextWhileWait("...Loading Info")
							loadPageInfo()
						})
					}
				})
			}
		})
	}

	const handleUnlock = () => {
		unlockInvoice(invoiceID, me.username, (data) => {
			if (data.unlock == true) {
				loadPageInfo()
			} else {
				setOpenWait(true)
				setTextWhileWait("Can NOT unlock invoice. Invoice has an AP record.")
			}
		})
	}

	const handleReviewClick = () => {
		createDisputeHistory(invoiceID, me.username, (data) => {
			history.push("/invoicereviewworkflow/" + (invoice ? invoice.id_ : -1))
		})
	}

	const popoverInvoiceRecordDisputeDetails = (id: number, disable: boolean) => {
		return (
			<InvoiceRecordDisputeDetails
				invoiceRecordID={id}
				disableControls={disable ? disable : false}
			/>
		)
	}

	const popoverInvoiceRecordApprovalDetails = (id: number, disableControls: boolean) => {
		return <ApprovCriteria invoiceRecordID={id} disableControls={disableControls} />
	}

	const sortDisputeReasons = (disputeReasons: DisputeReasonDTO[]) => {
		const sortedDisputeReasons = disputeReasons.sort((a, b) => {
			return -descendingComparator(a, b, "reason")
		})
		return sortedDisputeReasons
	}

	const sortAndSetDisputeReasons = (disputeReasons: DisputeReasonDTO[]) => {
		const sortedDisputeReasons = sortDisputeReasons(disputeReasons)
		setDisputeReasons(sortedDisputeReasons)
	}

	const loadPageInfo = () => {
		if (!statusList) {
			getStatusListByCategory("invoice", setStatusList)
		}

		if (!disputeReasons) {
			getDisputeReasons(sortAndSetDisputeReasons)
		}

		getInvoice(+invoiceID, setInvoice)

		getInvoiceReconciliationsByID(invoiceID).then((data) => {
			if (data) {
				setInvoiceReconciliations(data)
			}
		})
	}

	React.useEffect(() => {
		if (searchText) {
			const approvedfiltered = approvedRecsRef.current.filter((item) => {
				if (item.chassis && item.chassis.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (item.container1 && item.container1.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (
					item.matches &&
					item.matches.length > 0 &&
					item.matches[0].full_manifest.toUpperCase().includes(searchText.toUpperCase())
				) {
					return true
				}
				if (item.id_.toString() == searchText) {
					return true
				}
			})
			const pendingfiltered = pendingRecsRef.current.filter((item) => {
				if (item.chassis && item.chassis.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (item.container1 && item.container1.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (
					item.matches &&
					item.matches.length > 0 &&
					item.matches[0].full_manifest.toUpperCase().includes(searchText.toUpperCase())
				) {
					return true
				}
				if (item.id_.toString() == searchText) {
					return true
				}
			})
			const disputedfiltered = disputedRecs.filter((item) => {
				if (item.chassis && item.chassis.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (item.container1 && item.container1.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (
					item.matches &&
					item.matches.length > 0 &&
					item.matches[0].full_manifest.toUpperCase().includes(searchText.toUpperCase())
				) {
					return true
				}
				if (item.id_.toString() == searchText) {
					return true
				}
			})
		}
	}, [searchText])

	React.useEffect(() => {
		if (searchedRecId) {
			const approvedfiltered = approvedRecsRef.current.filter((item) => {
				if (item.id_ == Number.parseInt(searchedRecId)) {
					return true
				}
			})
			const pendingfiltered = pendingRecsRef.current.filter((item) => {
				if (item.id_ == Number.parseInt(searchedRecId)) {
					return true
				}
			})
			const disputedfiltered = disputedRecs.filter((item) => {
				if (item.id_ == Number.parseInt(searchedRecId)) {
					return true
				}
			})
			const foundRec = pendingfiltered?.length
				? pendingfiltered
				: approvedfiltered?.length
					? approvedfiltered
					: disputedfiltered
			if (foundRec?.length) {
				foundRec.forEach((item) => {
					if (item.record_status == 1) {
						setValue(0)
					}
					if (item.record_status == 2) {
						setValue(1)
					}
					if (item.record_status == 3) {
						setValue(2)
					}
				})
			}
			localStorage.setItem("invoiceWorkflowRecID", null)
		}
	}, [searchedRecId])

	React.useEffect(() => {
		if (disputedRecs) {
			disputedRecs.forEach((rec) => {
				if (rec.matches && rec.matches.length > 0) {
					rec.booking_bol = rec.matches[0].booking_bol
					rec.ar_chuse = rec.matches[0].ar_chuse?.toString() ?? rec.matches[0].ar_trx?.toString()
					rec.customer_name = rec.matches[0].customer_name

					if (rec.matches.length > 1) {
						rec.manifest = "'" + rec.manifest_num
					} else {
						rec.manifest = rec.manifest_num
					}
				}
			})
			setDisputedFilteredRecs(disputedRecs)
		}
	}, [disputedRecs])

	const addPropertiesToArray = (recs: IEPInvoicePlus[]) => {
		recs.forEach((rec) => {
			if (rec.matches && rec.matches.length) {
				rec.booking_bol = rec.matches[0].booking_bol
				rec.ar_chuse = rec.matches[0].ar_chuse?.toString() ?? rec.matches[0].ar_trx?.toString()
				rec.customer_name = rec.matches[0].customer_name

				if (rec.matches.length > 1) {
					rec.manifest = "'" + rec.manifest_num
				} else {
					rec.manifest = rec.manifest_num
				}
			}
		})

		return recs
	}

	const setDisputedAndCredited = (dispCreditRecs: IEPInvoicePlus[]) => {
		const tempCredited = []
		const tempDisputed = []

		dispCreditRecs.forEach((rec) => {
			if (rec.dispute_transactions && rec.dispute_transactions.length > 0) {
				const disputeForRecord = rec.dispute_transactions.find(
					(d) => d.invoice_record_id == rec.id_ && (d.credit_amt ?? 0) > 0,
				)
				if (disputeForRecord) {
					tempCredited.push(rec)
				} else {
					tempDisputed.push(rec)
				}
			}
		})

		setDisputedRecs(tempDisputed)
		setCreditedRecs(tempCredited)
	}

	const addPropertiesToTerminalRejected = (recs: IEPInvoicePlus[]) => {
		recs.forEach((rec) => {
			if (rec.matches && rec.matches.length > 0) {
				rec.booking_bol = rec.matches[0].booking_bol
				rec.ar_chuse = rec.matches[0].ar_chuse.toString() ?? rec.matches[0].ar_trx.toString()
				rec.customer_name = rec.matches[0].customer_name

				if (rec.matches.length > 1) {
					rec.manifest = "'" + rec.manifest_num
				} else {
					rec.manifest = rec.manifest_num
				}
			}
		})

		return recs
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<WaitPopup
					open={openWait}
					closeOnRequest={() => {
						setOpenWait(false)
					}}>
					{textWhileWait}
				</WaitPopup>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openRecSearch}
					onClose={handleRecSearchClose}>
					<div style={modalStyle} className={classes.modal}>
						<InvRecFilter setSearchText={setSearchText} setResetSearch={setResetSearch} />
					</div>
				</Modal>
				{invoice ? (
					Object.keys(invoice).length ? (
						<Box style={{ padding: 30 }}>
							<Grid container style={{ overflow: "hidden", width: "100%" }}>
								<Grid item sm={12}>
									<Grid container>
										<Grid item sm={9}>
											<Box>
												<h3>
													IEP INVOICE - #{invoice ? invoice.invoice_num : ""} -{" "}
													{invoice
														? formatDateMMDDYYYY(new Date(invoice.documented_dt)) // TODO: validate the correct date
														: null}{" "}
													-{invoice ? invoice.iep_name : ""}
												</h3>
											</Box>
										</Grid>
										<Grid item sm={3} style={{ whiteSpace: "nowrap" }}>
											<div style={{ float: "right" }}>
												<Button
													className={classes.button}
													size="small"
													disabled={
														invoice ? !!(invoice.is_completed || invoice.ignore_update_dt) : false
													}
													variant="outlined"
													color="primary"
													onClick={handleReValidate}>
													ReValidate
												</Button>
												<Button
													className={classes.button}
													size="small"
													disabled={
														invoice && invoice.is_completed ? !!invoice.ignore_update_dt : true
													}
													variant="outlined"
													color="primary"
													onClick={handleUnlock}>
													CLERICAL CHANGE
												</Button>
												{!pendingRecsRef.current.length ? (
													<Button
														className={classes.button}
														size="small"
														variant="contained"
														color="primary"
														onClick={handleReviewClick}>
														Review
													</Button>
												) : (
													<Button
														disabled
														className={classes.button}
														size="small"
														variant="contained"
														color="primary">
														Review
													</Button>
												)}
											</div>
										</Grid>
									</Grid>
								</Grid>
								<Grid item sm={12}>
									<Tabs value={value} onChange={handleChange}>
										<Tab
											label={`Needs Review (${pendingRecsRef.current.length})`}
											{...a11yProps(0)}
										/>
										<Tab label={`Approved (${approvedRecsRef.current.length})`} {...a11yProps(1)} />
										<Tab label={`To Dispute (${disputedRecs.length})`} {...a11yProps(2)} />
										<Tab label={`Credited (${creditedRecs.length})`} {...a11yProps(3)} />
										<Tab
											label={`Terminal Rejected (${terminalRejectedRef.current.length})`}
											{...a11yProps(4)}
										/>
									</Tabs>
									<Box>
										<TabPanel value={value} index={0}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={false}
												viewReview={true}
												viewPopOver={true}
												invoiceData={pendingFilteredRecs ? pendingFilteredRecs : []}
												statusData={statusList ? statusList : []}
												disputeReasonData={disputeReasons ? disputeReasons : []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={invoice && invoice.ignore_update_dt ? true : false}
												disableControls={false}
												disableDropdown={false}
											/>
										</TabPanel>
										<TabPanel value={value} index={1}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={false}
												viewReview={true}
												viewPopOver={true}
												invoiceData={approvedFilteredRecs ? approvedFilteredRecs : []}
												statusData={statusList ? statusList : []}
												disputeReasonData={disputeReasons ? disputeReasons : []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={invoice && invoice.ignore_update_dt ? true : false}
												disableControls={false}
												disableDropdown={false}
											/>
										</TabPanel>
										<TabPanel value={value} index={2}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={true}
												viewReview={true}
												viewPopOver={true}
												invoiceData={disputedFilteredRecs || []}
												statusData={statusList || []}
												disputeReasonData={disputeReasons || []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={invoice && invoice.ignore_update_dt ? true : false}
												disableControls={false}
												disableDropdown={false}
											/>
										</TabPanel>
										<TabPanel value={value} index={3}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={true}
												viewReview={true}
												viewPopOver={true}
												invoiceData={creditedRecs || []}
												statusData={statusList || []}
												disputeReasonData={disputeReasons || []}
												PopoverControl={popoverInvoiceRecordApprovalDetails}
												maxHeight={600}
												isComplete={invoice ? invoice.is_completed : false}
												isUnlocked={invoice && invoice.ignore_update_dt ? true : false}
												disableControls={false}
												disableDropdown={false}
											/>
										</TabPanel>
										<TabPanel value={value} index={4}>
											<InvoiceNeedsReview
												viewTrinium={true}
												viewReason={true}
												viewReview={true}
												viewPopOver={true}
												invoiceData={terminalRejectedRef.current}
												statusData={statusList ? statusList : []}
												disputeReasonData={disputeReasons ? disputeReasons : []}
												PopoverControl={popoverInvoiceRecordDisputeDetails}
												maxHeight={600}
												disableControls={false}
												disableDropdown={false}
											/>
										</TabPanel>
									</Box>
								</Grid>
							</Grid>
						</Box>
					) : (
						"Invoice not found"
					)
				) : null}
			</Paper>
		</Layout>
	)
}
