export interface DataApSums {
	id_: number
	terminal_abbrv: string
	sum_of_approval_amt: string
	sum_of_rebill_amt: string
	terminal_alias_code: string
}

export const HeaderDataApSums: HeadCell[] = [
	{
		id: "terminal_abbrv",
		numeric: false,
		disablePadding: false,
		label: "TERMINALS",
	},
	{ id: "sum_of_approval_amt", numeric: false, disablePadding: false, label: "SUM OF APPROVED" },
	{ id: "sum_of_rebill_amt", numeric: false, disablePadding: false, label: "SUM OF REBILL" },
	{ id: "terminal_alias_code", numeric: false, disablePadding: false, label: "CODE" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataApSums
	label: string
	numeric: boolean
}
