import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Modal from "@material-ui/core/Modal"
import Paper from "@material-ui/core/Paper"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Typography from "@material-ui/core/Typography"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import React from "react"
import { Link } from "react-router-dom"
import {
	getCCM,
	getCCMRecords,
	getIepList,
	getStatusListByCategory,
	validateCCMRecords,
} from "../api"
import CCMPossMatches from "../components/CustomTable/ccmPossMatchesTable/CCMPossMatches"
import RecordSearch from "../components/RecordSearch"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { formatDateMMDDYYYY } from "../lib/formatters"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

function rand() {
	return Math.round(Math.random() * 10) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()

	return {
		top: `${10}%`,
		left: `${3}%`,
		overflow: "auto",
	}
}

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && <Box paddingTop={0}>{children}</Box>}
		</Typography>
	)
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	}
}

export default function (props: any) {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)
	const [modalStyle] = React.useState(getModalStyle)
	const [records, setRecords] = React.useState<any>()
	const [ccm, setCCM] = React.useState()
	const [iepList, setIepList] = React.useState<any | undefined>()
	const [dateFrom, setDateFrom] = React.useState<Date>()
	const [dateTo, setDateTo] = React.useState<Date>()
	const [statusList, setStatusList] = React.useState<any | undefined>()
	const [possibleRecs, setPossibleRecs] = React.useState<any | undefined>()
	const [matchedRecs, setMatchedRecs] = React.useState<any | undefined>()
	const [noMatchedRecs, setNoMatchedRecs] = React.useState<any | undefined>()
	const [open, setOpen] = React.useState(false)
	const [openWait, setOpenWait] = React.useState<boolean>(true)
	const [textWhileWait, setTextWhileWait] = React.useState("...Loading Info")
	const today = new Date()
	const ccmID = props.match.params.id

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (records) {
			const dates: Date[] = []
			records.forEach((element: any) => {
				if (element?.cms_move_dt) {
					dates.push(new Date(element.cms_move_dt.replace(" GMT", "")))
				}
			})
			const dateSorted = dates.sort((a: any, b: any) => {
				return a - b
			})
			setDateFrom(dateSorted[0])
			setDateTo(dateSorted[dateSorted.length - 1])
		}
	}, records)

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
		setOpenWait(true)
		loadPageInfo()
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleReValidate = () => {
		setTextWhileWait("...Validating CCM")
		setOpenWait(true)
		validateCCMRecords(ccmID, () => {
			setTextWhileWait("...Loading Info")
			loadPageInfo()
		})
	}

	const loadPageInfo = () => {
		if (!records) {
			getCCMRecords(ccmID, setRecords)
		}
		if (!iepList) {
			getIepList(setIepList)
		}
		if (!ccm) {
			getCCM(ccmID, setCCM)
		}
		if (!statusList) {
			getStatusListByCategory("ccm", setStatusList)
		}
		getCCMRecords(ccmID, setPossibleRecs, "possible")
		getCCMRecords(ccmID, setMatchedRecs, "matched")
		getCCMRecords(ccmID, setNoMatchedRecs, "nomatched")
	}

	React.useEffect(() => {
		if (possibleRecs) {
			possibleRecs.forEach((rec) => {
				if (rec.updated_dt && typeof rec.updated_dt != typeof today) {
					rec.updated_dt = new Date(rec.updated_dt.replace(" GMT", ""))
				}
			})
		}
		if (matchedRecs) {
			matchedRecs.forEach((rec) => {
				if (rec.updated_dt && typeof rec.updated_dt != typeof today) {
					rec.updated_dt = new Date(rec.updated_dt.replace(" GMT", ""))
				}
			})
		}
		if (noMatchedRecs) {
			noMatchedRecs.forEach((rec) => {
				if (rec.updated_dt && typeof rec.updated_dt != typeof today) {
					rec.updated_dt = new Date(rec.updated_dt.replace(" GMT", ""))
				}
			})
		}
		if (possibleRecs && matchedRecs && noMatchedRecs && dateFrom && dateTo) {
			setOpenWait(false)
		}
	}, [possibleRecs, matchedRecs, noMatchedRecs, dateFrom, dateTo])

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<WaitPopup open={openWait}>{textWhileWait}</WaitPopup>
				<Box style={{ padding: 30 }}>
					<Grid container style={{ overflow: "hidden", width: "100%" }}>
						<Grid item sm={12}>
							<Grid container>
								<Grid item sm={8}>
									<Box>
										<h3>
											CCM MOVES BETWEEN {dateFrom ? formatDateMMDDYYYY(dateFrom) : ""} AND{" "}
											{dateTo ? formatDateMMDDYYYY(dateTo) : ""}
										</h3>
									</Box>
								</Grid>
								<Grid item sm={2}>
									<Button
										size="small"
										variant="outlined"
										color="primary"
										onClick={handleReValidate}>
										ReValidate
									</Button>
								</Grid>
								<Grid item sm={1}>
									<Button onClick={handleOpen}>
										<SearchIcon fontSize="large" />
									</Button>
									<Modal
										aria-labelledby="simple-modal-title"
										aria-describedby="simple-modal-description"
										open={open}
										onClose={handleClose}>
										<div style={modalStyle} className={classes.paper}>
											<RecordSearch isClosed={handleClose} />
										</div>
									</Modal>
								</Grid>
								<Grid item sm={1}>
									{possibleRecs && possibleRecs.length <= 0 ? (
										<Link to={"/ccmreviewworkflow/" + ccmID}>
											<Button size="small" variant="contained" color="primary">
												Review
											</Button>
										</Link>
									) : (
										<Button disabled size="small" variant="contained" color="primary">
											Review
										</Button>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={12}>
							<Tabs value={value} onChange={handleChange}>
								<Tab
									label={"Possible Matches (" + (possibleRecs ? possibleRecs.length : 0) + ")"}
									{...a11yProps(0)}
								/>
								<Tab
									label={"Matched (" + (matchedRecs ? matchedRecs.length : 0) + ")"}
									{...a11yProps(1)}
								/>
								<Tab
									label={"No Matches (" + (noMatchedRecs ? noMatchedRecs.length : 0) + ")"}
									{...a11yProps(2)}
								/>
							</Tabs>
							<Box>
								<TabPanel value={value} index={0}>
									<CCMPossMatches
										ccmData={possibleRecs ? possibleRecs : []}
										iepList={iepList ? iepList : []}
										statusList={statusList ? statusList : []}
										viewFirstColumn={true}
										maxHeight={600}
									/>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<CCMPossMatches
										ccmData={matchedRecs ? matchedRecs : []}
										iepList={iepList ? iepList : []}
										statusList={statusList ? statusList : []}
										viewFirstColumn={true}
										maxHeight={600}
									/>
								</TabPanel>
								<TabPanel value={value} index={2}>
									<CCMPossMatches
										ccmData={noMatchedRecs ? noMatchedRecs : []}
										iepList={iepList ? iepList : []}
										statusList={statusList ? statusList : []}
										viewFirstColumn={true}
										maxHeight={600}
									/>
								</TabPanel>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
