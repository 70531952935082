import { API_V2_URL } from "../env";
import { toastStore } from "../stores/toastStore";

async function apiCallBase(
	method: string,
	route: string,
	data: any = null,
	hasAttachment = false,
): Promise<Response> {
	const req: RequestInit = {
		credentials: "include",
		headers: new Headers({}),
		method,
	}

	if (data !== null && data !== undefined && !hasAttachment) {
		req.body = JSON.stringify(data)
		;(req.headers as Headers).append("Content-Type", "application/json")
	}
	if (data !== null && data !== undefined && hasAttachment) {
		req.body = data
	}

	let res: Response

	try {
		res = await fetch(API_V2_URL + route, req)
	} catch (err) {
		throw new APIError("Network error", err)
	}
	return res
}

class APIError extends Error {
	originalError: any
	constructor(message: string, error: any) {
		super(message)
		this.name = "APIError"
		this.originalError = error
	}
}

export async function apiCall<T = any>(
	method: string,
	route: string,
	data: any = null,
	hasAttachment = false,
	isDownloadFile = false,
): Promise<T> {
	const res = await apiCallBase(method, route, data, hasAttachment)

	if (isDownloadFile) {
		return res as any
	}

	let resBody: any
	try {
		resBody = await res.json()
		if (resBody.statusCode === 200) {
			return resBody.data as T
		}
	} catch (err) {
		toastStore.showToast("error", "Unexpected error while parsing response")
		throw new APIError("Bad error", err)
	}
}
