import { IconButton } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import DownloadIcon from "@material-ui/icons/GetApp"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import {
	emailIEPDisputeForm,
	getAPIURL,
	getDisputeReasons,
	getInvoice,
	getInvoiceRecords,
	getTerminalsForInvoice,
	markInvoicesAsCompleted,
	sendToTerminal,
} from "../api"
import InvoiceNeedsReview from "../components/CustomTable/invoiceWorkflowTable/InvoiceNeedsReview"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { authStore } from "../stores/authStore"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: 900,
			maxHeight: 700,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && <Box paddingTop={0}>{children}</Box>}
		</Typography>
	)
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	}
}

async function download(invoiceID: number, callback: Function) {
	return fetch("http://localhost:5050/export/dispute_form/invoice_id/" + invoiceID).then(
		(response) => {
			response.blob()
		},
	)
}

export default function (props: any) {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)
	const [radioValue, setradioValue] = React.useState("term")
	const [approved, setApproved] = React.useState<any | undefined>()
	const [disputed, setDisputed] = React.useState<any | undefined>()
	const [merged, setMerged] = React.useState<any | undefined>()
	const [invoice, setInvoice] = React.useState<any | undefined>()
	const [terminals, setTerminals] = React.useState<any | undefined>()
	const [selectedTerminal, setSelectedTerminal] = React.useState<any | undefined>(-1)
	const [disputeReasons, setDisputeReasons] = React.useState<any | undefined>()
	const [sentToTerminal, setSentToTerminal] = React.useState<any | undefined>()
	const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info")
	const [openWait, setOpenWait] = React.useState<boolean>(true)
	const [markComplete, setMarkComplete] = React.useState<any | undefined>()
	const [sendToIEP, setSendToIEP] = React.useState<any | undefined>()
	const [downloadDisputedForm, setDownloadDisputeForm] = React.useState<any | undefined>()
	const invoiceID = props.match.params.id
	const auth = authStore.use()
	const me = auth.me
	const history = useHistory()

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (sentToTerminal) {
			setOpenWait(false)
			history.push("/dashboard")
		}
	}, [sentToTerminal])

	React.useEffect(() => {
		if (markComplete) {
			const sendToIepObj: any = {}
			sendToIepObj.invoice_id = invoiceID
			sendToIepObj.username = me.username

			setOpenWait(true)
			setWaitMsg("...Sending IEP Email.")
			emailIEPDisputeForm(sendToIepObj, setSendToIEP)
		}
	}, [markComplete])

	React.useEffect(() => {
		if (sendToIEP) {
			setOpenWait(false)
			history.push("/dashboard")
		}
	}, [sendToIEP])

	const loadPageInfo = () => {
		if (!invoice) {
			getInvoice(invoiceID, setInvoice)
		}
		if (!terminals) {
			getTerminalsForInvoice(invoiceID, setTerminals)
		}
		if (!disputeReasons) {
			getDisputeReasons(setDisputeReasons)
		}
		getInvoiceRecords(invoiceID, setApproved, "approved", null, null)

		getInvoiceRecords(invoiceID, setDisputed, "disputed", null, null)
		getInvoiceRecords(invoiceID, setMerged, null, null, null)
	}

	const handleDDSwitchChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		id: number,
	) => {
		setSelectedTerminal(event.target.value)
	}

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setradioValue((event.target as HTMLInputElement).value)
	}

	const handleDownloadClick = () => {
		const doclink = document.createElement("a")
		doclink.href =
			getAPIURL() +
			"export/dispute_form/invoice_id/" +
			invoiceID +
			"/" +
			me.username +
			"/" +
			Math.floor(Math.random() * 100000)
		doclink.target = "blank"
		doclink.click()
	}

	React.useEffect(() => {
		if (downloadDisputedForm) {
		}
	}, [downloadDisputedForm])

	const handleFinishSendClick = () => {
		if (radioValue === "term") {
			const sendToTerminalObj: any = {}
			sendToTerminalObj.invoice_id = invoiceID
			sendToTerminalObj.username = me.username
			sendToTerminalObj.terminalToSend = selectedTerminal
			setWaitMsg("...Sending Approved Invoice Records to Terminal")
			setOpenWait(true)
			sendToTerminal(sendToTerminalObj, setSentToTerminal)
		} else {
			setWaitMsg("...Marking Invoice as Complete")
			setOpenWait(true)

			markInvoicesAsCompleted(invoiceID, me.username, setMarkComplete)
		}
	}

	React.useEffect(() => {
		if (approved && disputed && merged) {
			setOpenWait(false)
		}
	}, [approved, disputed, merged])

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<WaitPopup open={openWait}>{waitMsg}</WaitPopup>
				<Box style={{ padding: 30 }}>
					<Grid container style={{ overflow: "hidden", width: "100%" }}>
						<Grid item sm={12}>
							<Box>
								<h3>REVIEW - INVOICE #{invoice ? invoice.invoice_num : ""}</h3>
							</Box>
						</Grid>
						<Grid item sm={12}>
							<Tabs value={value} onChange={handleChange}>
								<Tab
									label={"Aproved (" + (approved ? approved.length : 0) + ")"}
									{...a11yProps(0)}
								/>
								<Tab
									label={"Disputed (" + (disputed ? disputed.length : 0) + ")"}
									{...a11yProps(1)}
								/>
								<Tab label={"Merged (" + (merged ? merged.length : 0) + ")"} {...a11yProps(2)} />
							</Tabs>
							<Box>
								<TabPanel value={value} index={0}>
									<InvoiceNeedsReview
										viewTrinium={false}
										viewReason={false}
										viewReview={false}
										viewPopOver={false}
										invoiceData={approved ? approved : []}
										statusData={[]}
										disputeReasonData={disputeReasons ? disputeReasons : []}
										disableControls={true}
										maxHeight={300}
									/>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<InvoiceNeedsReview
										viewTrinium={false}
										viewReason={true}
										viewReview={false}
										viewPopOver={false}
										invoiceData={disputed ? disputed : []}
										statusData={[]}
										disputeReasonData={disputeReasons ? disputeReasons : []}
										disableControls={true}
										maxHeight={300}
									/>
								</TabPanel>
								<TabPanel value={value} index={2}>
									<InvoiceNeedsReview
										viewTrinium={false}
										viewReason={true}
										viewReview={false}
										viewPopOver={false}
										invoiceData={merged ? merged : []}
										statusData={[]}
										disputeReasonData={disputeReasons ? disputeReasons : []}
										disableControls={true}
										maxHeight={300}
										displayAllItems={true}
									/>
								</TabPanel>
							</Box>
						</Grid>
						<Grid item sm={12}>
							<Grid container>
								<Grid item sm={3}>
									<Grid container>
										<Grid item sm={12}>
											<h5>SAVE</h5>
										</Grid>
										<Grid item sm={12}>
											<IconButton onClick={handleDownloadClick} size="small">
												<DownloadIcon />
												<h5>Download Dispute Form</h5>
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
								<Grid item sm={6}>
									<Grid container>
										<Grid item sm={12}>
											<h5>SEND</h5>
										</Grid>
										<Grid item sm={12}>
											<RadioGroup
												aria-label="gender"
												name="gender1"
												value={radioValue}
												onChange={handleRadioChange}>
												<Grid container>
													<Grid item sm={6}>
														<FormControlLabel
															value="term"
															control={<Radio style={{ color: "#707070" }} />}
															label="Terminal"
														/>
														<FormControlLabel
															value="terminal"
															label=""
															control={
																<div>
																	<TextField
																		disabled={props.disableControls}
																		size="small"
																		select
																		variant="outlined"
																		value={selectedTerminal}
																		style={{ maxWidth: 100 }}
																		onChange={(event) => handleDDSwitchChange(event, null)}>
																		<MenuItem value={-1}>{"All Terminals"}</MenuItem>
																		{terminals
																			? terminals.map((terminal: any) => {
																					return (
																						<MenuItem value={terminal.id_}>
																							{terminal.terminal_code}
																						</MenuItem>
																					)
																				})
																			: null}
																	</TextField>
																</div>
															}
														/>
													</Grid>
													<Grid item sm={6}>
														<FormControlLabel
															value="iep"
															label="IEP"
															control={<Radio style={{ color: "#707070" }} />}
														/>
													</Grid>
												</Grid>
											</RadioGroup>
										</Grid>
									</Grid>
								</Grid>
								<Grid item sm={6}></Grid>
								<Grid item sm={12}>
									<Button
										variant="contained"
										color="primary"
										style={{ margin: 10, float: "right" }}
										onClick={handleFinishSendClick}>
										Finish & Send
									</Button>
									<Link to={"/invoiceworkflow/" + (invoice ? invoice.id_ : -1)}>
										<Button
											variant="outlined"
											color="primary"
											style={{ margin: 10, float: "right" }}>
											Back
										</Button>
									</Link>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
