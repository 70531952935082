import * as React from "react"
import useIsMountedRef from "./useIsMountedRef"

interface AsyncLoaderState<T> {
	waiting: boolean
	error: string | null
	data: T
}

interface AsyncLoader<T> extends AsyncLoaderState<T> {
	load(): Promise<void>
	loadUpdate(): Promise<void>
	setData(data: T): void
}

function useAsyncLoader<T>(fallback: T, loader: () => T | Promise<T>): AsyncLoader<T> {
	const isMountedRef = useIsMountedRef()

	const [state, setStateBase] = React.useState<AsyncLoaderState<T>>({
		waiting: false,
		error: null,
		data: fallback,
	})

	function setState(state: AsyncLoaderState<T>) {
		if (isMountedRef.current) {
			setStateBase(state)
		}
	}

	return { ...state, load, loadUpdate, setData }

	async function loadUpdate() {
		setState({ waiting: true, error: null, data: state.data })
		try {
			const data = await loader()
			setState({ waiting: false, error: null, data })
		} catch (err) {
			setState({ waiting: false, error: err + "", data: state.data })
		}
	}

	async function load() {
		setState({ waiting: true, error: null, data: fallback })
		try {
			const data = await loader()
			setState({ waiting: false, error: null, data })
		} catch (err) {
			setState({ waiting: false, error: err + "", data: fallback })
		}
	}

	function setData(data: T) {
		setState({ ...state, data })
	}
}

export default useAsyncLoader
