import { Grid, Table, TableCell, TableHead, TableRow } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import InputBase from "@material-ui/core/InputBase"
import MenuItem from "@material-ui/core/MenuItem"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { quickSearch } from "../../api"
import { formatDateMMDDYYYY } from "../../lib/formatters"
import type { IIepRecord } from "../../types/IepRecord"
import SubHighlight from "../SubHighlight"
import MsgPopup from "../WaitPopup"
import ProfileHeader from "./ProfileHeader"

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		toolbar: {
			display: "flex",
			background: "#fff",
			alignItems: "center",
			width: "100%",
		},
		search: {
			flexGrow: 1,
			width: "100%",
		},
		userText: {
			margin: "0 10px 0 10px !important",
			whiteSpace: "nowrap",
		},
		profilePicture: {
			color: "#fff",
			backgroundColor: theme.palette.primary.main + "!important",
		},
		userButton: {
			height: "100%",
			padding: theme.spacing(0, 1),
		},
	}),
)

export default function () {
	const classes = useStyle()

	const searchInputRef = React.useRef<HTMLInputElement>(null)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [searchText, setSearchText] = React.useState<string | undefined>()
	const [searchRes, setSearchRes] = React.useState<IIepRecord[]>()
	const [isSearching, setIsSearching] = React.useState<boolean>(false)
	const [msgOpen, setMsgOpen] = React.useState<boolean | undefined>(false)
	const history = useHistory()

	React.useEffect(() => {
		if (searchRes || isSearching) {
			setMsgOpen(true)
		}
	}, [searchRes, setIsSearching])
	const handleMsgPopupClose = () => {
		setMsgOpen(false)
		setIsSearching(false)
		setSearchRes(undefined)
	}

	const handleQuickSearchClick = (event: React.MouseEvent<HTMLElement>, searchtxt: string) => {
		const filteredSearchtxt = searchtxt.split("/").join("-")
		quickSearch(filteredSearchtxt, setSearchRes)
		setAnchorEl(event.currentTarget)
	}

	const handleButtonLinkClick = (ccmID: number | null, invID: number, invRecID: number) => {
		if (ccmID) {
			history.push("/ccmworkflow/" + ccmID)
			history.go(0)
		}
		if (invID) {
			localStorage.setItem("invoiceWorkflowRecID", invRecID.toString())
			history.push("/invoiceworkflow/" + invID)
			history.go(0)
		}
	}

	const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			const filteredSearchtxt = searchText.split("/").join("-")
			setIsSearching(true)
			quickSearch(filteredSearchtxt, setSearchRes)
			setAnchorEl(e.currentTarget)
		}
	}

	return (
		<Grid container className={classes.toolbar}>
			{isSearching && searchRes == null ? (
				<MsgPopup open={isSearching} closeOnRequest={handleMsgPopupClose}>
					Searching....
				</MsgPopup>
			) : (
				<MsgPopup open={msgOpen || isSearching} closeOnRequest={handleMsgPopupClose}>
					<Table stickyHeader={true} style={{ whiteSpace: "nowrap" }}>
						<TableHead>
							<TableRow>
								<TableCell>Invoice Rec ID#</TableCell>
								<TableCell>Invoice #</TableCell>
								<TableCell>IEP</TableCell>
								<TableCell>AP Locked</TableCell>
								<TableCell>Manfiest</TableCell>
								<TableCell>Containers</TableCell>
								<TableCell>Chassis</TableCell>
							</TableRow>
						</TableHead>
						{searchRes && searchRes.length > 0 ? (
							searchRes.map((res) => {
								if (res) {
									if (res.invoice_num) {
										return (
											<TableRow>
												<TableCell>
													<Button
														color="primary"
														onClick={() => {
															handleButtonLinkClick(null, res.iep_invoice_id, res.id_)
														}}>
														<SubHighlight str={res.id_.toString()} subStr={searchText} />
													</Button>
												</TableCell>
												<TableCell>
													<SubHighlight str={res.invoice_num} subStr={searchText} />
												</TableCell>
												<TableCell>
													<SubHighlight str={res.iep_name} subStr={searchText} />
												</TableCell>
												<TableCell>{res.ap_summary_id ? "YES" : "NO"}</TableCell>
												<TableCell>
													<SubHighlight str={res.manifest_num} subStr={searchText} />
												</TableCell>
												<TableCell>
													<SubHighlight
														str={
															res.container2 && res.container1 != res.container2
																? res.container1 + ", " + res.container2
																: res.container1
														}
														subStr={searchText}
													/>
												</TableCell>
												<TableCell>
													<SubHighlight str={res.chassis} subStr={searchText} />
												</TableCell>
											</TableRow>
										)
									} else {
										return (
											<MenuItem>
												<b>CCM</b>:
												<Button
													color="primary"
													onClick={() => {
														handleButtonLinkClick(res.id_, null, null)
													}}>
													{formatDateMMDDYYYY(new Date(res.documented_dt?.replace(" GMT", ""))) +
														", "}
													&nbsp;
												</Button>
												<b>Name</b>:{res.name + ", "}
											</MenuItem>
										)
									}
								} else {
									return (
										<div>
											<h3>No Records</h3>
										</div>
									)
								}
							})
						) : (
							<div>
								<h3>No Records</h3>
							</div>
						)}
					</Table>
				</MsgPopup>
			)}
			<Grid item xs={1}>
				<IconButton
					aria-label="search"
					color="primary"
					onClick={(e) => {
						if (searchInputRef.current) {
							searchInputRef.current.focus()
							handleQuickSearchClick(e, searchText)
						}
					}}>
					<SearchIcon />
				</IconButton>
			</Grid>
			<Grid item xs={8}>
				<InputBase
					className={classes.search}
					placeholder="Search by Manifest ID, Container #, Chassis #,  or Invoice Record #."
					inputProps={{ "aria-label": "search" }}
					inputRef={searchInputRef}
					value={searchText}
					onKeyUp={handleEnterKey}
					onChange={(event) => {
						setSearchText(event.target.value)
					}}
					onKeyDown={(e) => {}}
				/>
			</Grid>
			<Grid item xs={3}>
				<ProfileHeader />
			</Grid>
		</Grid>
	)
}
