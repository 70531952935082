import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import clonedeep from "lodash/cloneDeep"
import React from "react"
import { updateTermComments, uploadTermChassisFiles } from "../../../api"
import { formatCurrency } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import { authStore } from "../../../stores/authStore"
import TerminalRejectResponce from "../../TerminalRejectResponce"
import WaitPopup from "../../WaitPopup"
import { useStyles } from "./styles"
import { type DataInvoiceRecons, type HeadCell, HeaderDataInvoiceRecons } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataInvoiceRecons) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
	const createSortHandler =
		(property: keyof DataInvoiceRecons) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				<TableCell
					style={{
						backgroundColor: "rgba(4, 101, 170, 1)",
						color: "white",
					}}>
					...
				</TableCell>
			</TableRow>
		</TableHead>
	)
}

interface Props {
	data: any[]
	handleDataChange?: Function
	PopoverControl?: Function
}

export default function (props: Props) {
	const auth = authStore.use()
	const me = auth.me
	const classes = useStyles()
	const [openWait, setOpenWait] = React.useState<boolean>(false)
	const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info")
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("profitLost")
	const [selected, setSelected] = React.useState<string[]>([])
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof DataInvoiceRecons,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handlePopupClose = () => {
		setAnchorEl(null)
	}

	const handleRadioSelect = (event: any, id: number) => {
		const selectedRecord: any[] = props.data.filter((item) => {
			return item.id_ == id
		})
		if (selectedRecord) {
			if (event.target.value === "reject") {
				setAnchorEl(event.currentTarget)
			}
			selectedRecord[0].selection = event.target.value
		}
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected: string[] = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}

		setSelected(newSelected)
	}

	const handleRecComment = (event: any, recordId: number) => {
		const selectedRecord: any[] = props.data.filter((item) => {
			return item.id_ == recordId
		})
		if (selectedRecord) {
			selectedRecord[0].comment = event.target.value

			props.handleDataChange(clonedeep(props.data))
		}
	}

	const handleRecFile = (file: any, recordId: number) => {
		const recordList = clonedeep(props.data)
		recordList.forEach((item) => {
			if (item.id_ === recordId) {
				item.file = file
			}
		})
		props.handleDataChange(recordList)
	}

	const handleSave = (row) => {
		const termComment: any = {}
		termComment.comment = row.comment
		termComment.username = me.username
		if (row.selection && row.selection === "reject") {
			termComment.isResponseAccepted = false
		} else if (row.selection && row.selection == "accept") {
			termComment.isResponseAccepted = true
		}
		setOpenWait(true)
		setWaitMsg("...Updating Record")
		updateTermComments(row.id_, termComment, (data) => {
			if (row.file) {
				const formData = new FormData()
				const additdata = '{"username":"' + me.username + '"}'
				formData.append("data", additdata)
				formData.append("file", row.file)
				setOpenWait(true)
				setWaitMsg("...Uploading File")
				uploadTermChassisFiles(formData, row.id_, true).then(() => {
					row.saved = true
					setOpenWait(false)
				})
			}
			const tempData = props.data
			tempData.forEach((element) => {
				if (row.id_ == element.id_) {
					element.saved = true
				}
			})
			props.handleDataChange(tempData)
			setOpenWait(false)
		})
	}

	const checkOrEx = (flag: boolean) => {
		if (flag) {
			return <CheckIcon className={classes.check} />
		} else {
			return <ClearIcon className={classes.clear} />
		}
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1
	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<WaitPopup open={openWait}>{waitMsg} </WaitPopup>
			<TableContainer style={{ maxHeight: "800px", width: "100%" }}>
				<Table id="InvoiceRec" stickyHeader={true} size={"small"}>
					<SortableTableHeaders
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={props.data ? props.data.length : 0}
						headCells={HeaderDataInvoiceRecons}
					/>
					<TableBody>
						{props.data &&
							stableSort(props.data, getComparator(order, orderBy)).map((row, index) => {
								const isItemSelected = isSelected(row.id_)
								return (
									<React.Fragment key={row.id_}>
										<TableRow
											key={row.id_}
											hover
											onClick={(event) => handleClick(event, row.id_)}
											tabIndex={-1}
											selected={isItemSelected}>
											<TableCell>{row.dt_in ? row.dt_in.format("L") : ""}</TableCell>
											<TableCell>{row.dt_out ? row.dt_out.format("L") : ""}</TableCell>
											<TableCell>
												{row.container1
													? row.container1.toUpperCase()
													: row.container2
														? row.container2.toUpperCase()
														: ""}
											</TableCell>
											<TableCell>{row.chassis ? row.chassis.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.manifest_num ? row.manifest_num.toUpperCase() : ""}
											</TableCell>
											<TableCell>
												{row.customer_name ? row.customer_name.toUpperCase() : ""}
											</TableCell>
											<TableCell>{row.invoice_ssl ? row.invoice_ssl.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.profitLost ? "$" + formatCurrency(row.profitLost) : "$0.00"}
											</TableCell>
											<TableCell>
												{row.total_billed ? "$" + formatCurrency(row.total_billed) : "$0.00"}
											</TableCell>
											{row.profitLost < 0 ? (
												<TableCell>
													<Grid container>
														<Grid item sm={12}>
															<Grid container>
																<Grid item sm={6}>
																	<div>
																		<FormControl component="fieldset">
																			<RadioGroup
																				value={row.selection}
																				onClick={(event) => {
																					handleRadioSelect(event, row.id_)
																				}}
																				onChange={(event) => {
																					handleRadioSelect(event, row.id_)
																				}}>
																				<FormControlLabel
																					value="accept"
																					control={<Radio style={{ color: "#707070" }} />}
																					label="Accept"
																				/>
																				<FormControlLabel
																					value="reject"
																					control={<Radio style={{ color: "#707070" }} />}
																					label="Reject"
																				/>
																			</RadioGroup>
																		</FormControl>
																	</div>
																</Grid>
																<Grid item sm={6}>
																	<Button
																		variant="contained"
																		color="primary"
																		onClick={() => {
																			handleSave(row)
																		}}
																		disabled={
																			row.comment == undefined &&
																			(row.selection == "reject" ||
																				row.selection == undefined ||
																				row.saved)
																				? true
																				: false
																		}>
																		{row.saved ? "SAVED" : "SAVE"}
																	</Button>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</TableCell>
											) : (
												<TableCell>{checkOrEx(true)}</TableCell>
											)}
										</TableRow>
										{(((row.terminal_status == 8 || row.terminal_status == 10) &&
											row.selection != "accept") ||
											row.selection == "reject") &&
										row.profitLost < 0 ? (
											<TableRow>
												<TableCell colSpan={8}>
													<TerminalRejectResponce
														invoiceRecordID={row.id_}
														handleRecordComment={handleRecComment}
														handleAddedFile={handleRecFile}></TerminalRejectResponce>
												</TableCell>
											</TableRow>
										) : (
											""
										)}
									</React.Fragment>
								)
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
