import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import { getDisputeReasons, getRecQuickSearch, getStatusListByCategory } from "../api"
import InvoiceNeedsReview from "../components/CustomTable/invoiceWorkflowTable/InvoiceNeedsReview"
import ApprovCriteria from "../components/InvoiceRecordApprovalCriteria"
import Layout from "../components/layout/Layout"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		wrapper: {
			width: "100%",
			height: "100%",
		},
		inputWrapper: {
			position: "absolute",
			overflow: "hidden",
			display: "inline",
			width: "100%",
			height: "50%",
			zIndex: 1,
			opacity: 0,
			left: 0,
			top: 0,
		},
		paper: {
			position: "absolute",
			width: 500,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

function getModalStyle() {
	const top = 50
	const left = 50
	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

interface Props {
	style: any
}

interface InvoiceRecord {
	dt_out: Date | null
	dt_in: Date | null
	customer_name: string | null
	matches: Array<Matches> | null
}

interface Matches {
	customer_name: string | null
}

export default function (props: any) {
	const [searchText, setSearchText] = React.useState<any | undefined>()
	const [disputeReasons, setDisputeReasons] = React.useState<any | undefined>()
	const [invoiceRecords, setInvoiceRecords] = React.useState<any | undefined>()
	const [approvedRecs, setapprovedRecs] = React.useState<any | undefined>()
	const [pendingRecs, setPendingRecs] = React.useState<any | undefined>()
	const [disputedRecs, setDisputedRecs] = React.useState<any | undefined>()
	const [statusList, setStatusList] = React.useState<any | undefined>()

	function transformInvoiceRecords(records: Array<InvoiceRecord>) {
		for (const record of records) {
			record.dt_out = record.dt_out ? new Date(record.dt_out) : null
			record.dt_in = record.dt_in ? new Date(record.dt_in) : null
			record.customer_name =
				record.matches && record.matches.length ? record.matches[0].customer_name : null
		}
		setInvoiceRecords(records)
	}
	React.useEffect(() => {
		if (searchText) {
			setInvoiceRecords([])
			getRecQuickSearch(searchText, transformInvoiceRecords)
			getDisputeReasons(setDisputeReasons)
			getStatusListByCategory("invoice", setStatusList)
		}
	}, [searchText])

	React.useEffect(() => {
		if (invoiceRecords) {
			const tempApprv = []
			const tempPend = []
			const tempDisp = []
			invoiceRecords.forEach((item) => {
				if (item.record_status == 1) {
					tempPend.push(item)
				} else if (item.record_status == 2) {
					tempApprv.push(item)
				} else if (item.record_status == 3) {
					tempDisp.push(item)
				}
			})
			setPendingRecs(tempPend)
			setapprovedRecs(tempApprv)
			setDisputedRecs(tempDisp)
		}
	}, [invoiceRecords])

	const popoverInvoiceRecordApprovalDetails = (id: number) => {
		return <ApprovCriteria invoiceRecordID={id} disableControls={true} />
	}

	if (searchText !== props.location.state.searchText) {
		setSearchText(props.location.state.searchText)
	}
	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<Box>
					<h3 style={{ paddingLeft: 15, paddingTop: 15 }}>REVIEW</h3>
				</Box>
				<Box style={{ marginLeft: 25, marginRight: 25, marginBottom: 10 }}>
					<InvoiceNeedsReview
						viewTrinium={false}
						viewReason={false}
						viewReview={true}
						viewPopOver={false}
						invoiceData={pendingRecs ? pendingRecs : []}
						statusData={statusList ? statusList : []}
						disputeReasonData={disputeReasons ? disputeReasons : []}
						maxHeight={600}
						disableControls={true}
						disableDropdown={true}
						quickSearchDisplays={true}
					/>
				</Box>
			</Paper>
			<Paper style={{ margin: 5 }}>
				<Box>
					<h3 style={{ paddingLeft: 15, paddingTop: 15 }}>APPROVED</h3>
				</Box>
				<Box style={{ marginLeft: 25, marginRight: 25, marginBottom: 10 }}>
					<InvoiceNeedsReview
						viewTrinium={false}
						viewReason={false}
						viewReview={true}
						viewPopOver={true}
						invoiceData={approvedRecs ? approvedRecs : []}
						statusData={statusList ? statusList : []}
						disputeReasonData={disputeReasons ? disputeReasons : []}
						maxHeight={600}
						disableControls={true}
						disableDropdown={true}
						quickSearchDisplays={true}
						PopoverControl={popoverInvoiceRecordApprovalDetails}
					/>
				</Box>
			</Paper>
			<Paper style={{ margin: 5 }}>
				<Box>
					<h3 style={{ paddingLeft: 15, paddingTop: 15 }}>DISPUTED</h3>
				</Box>
				<Box style={{ marginLeft: 25, marginRight: 25, marginBottom: 10 }}>
					<InvoiceNeedsReview
						viewTrinium={false}
						viewReason={false}
						viewReview={true}
						viewPopOver={true}
						invoiceData={disputedRecs ? disputedRecs : []}
						statusData={statusList ? statusList : []}
						disputeReasonData={disputeReasons ? disputeReasons : []}
						maxHeight={600}
						disableControls={true}
						disableDropdown={true}
						quickSearchDisplays={true}
						PopoverControl={popoverInvoiceRecordApprovalDetails}
					/>
				</Box>
			</Paper>
		</Layout>
	)
}
