export interface DataDisputeWorkflow {
	inv_rec_id: number
	invoice_num: string
	chassis: string
	container: string
	invoice_area: string
	iep_pool: string
	dispute_reason: string
	invoice_ssl: string
	iep_id: number
	attch: number
}

export const HeaderDataInvoiceRecons: HeadCell[] = [
	{ id: "inv_rec_id", numeric: false, disablePadding: false, label: "ID" },
	{ id: "invoice_num", numeric: false, disablePadding: false, label: "INVOICE #" },
	{ id: "chassis", numeric: false, disablePadding: false, label: "CHASSIS" },
	{
		id: "container",
		numeric: false,
		disablePadding: false,
		label: "CONTAINER",
	},
	{
		id: "invoice_area",
		numeric: false,
		disablePadding: false,
		label: "INVOICE AREA",
	},
	{
		id: "dispute_reason",
		numeric: false,
		disablePadding: false,
		label: "DISPUTE REASON",
	},
	{
		id: "invoice_ssl",
		numeric: false,
		disablePadding: false,
		label: "SSL",
	},
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataDisputeWorkflow
	label: string
	numeric: boolean
}
