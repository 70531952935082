import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Grid from "@material-ui/core/Grid"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import { getIepList } from "../api"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		wrapper: {
			width: "100%",
			height: "100%",
		},
		inputWrapper: {
			position: "absolute",
			overflow: "hidden",
			display: "inline",
			width: "100%",
			height: "50%",
			zIndex: 1,
			opacity: 0,
			left: 0,
			top: 0,
		},
	}),
)

interface Props {
	isClosed: Function
	handleSetIEPFilter: Function
}

export default function (props: Props) {
	const classes = useStyles()
	const [iepList, setIEPList] = React.useState<any | undefined>()
	const [selectedIEPs, setSelectedIEPs] = React.useState<number[] | undefined>([])

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {}, [selectedIEPs])

	const loadPageInfo = () => {
		getIepList(setIEPList)
	}

	const handleCheckChange = (event: any, id: number) => {
		if (event.target.checked) {
			const newList = 0
			setSelectedIEPs([id].concat(selectedIEPs))
		} else {
			setSelectedIEPs(
				selectedIEPs.filter((item) => {
					return item != id
				}),
			)
		}
	}

	const handleClose = () => () => {
		props.isClosed(true)
	}

	return (
		<Box>
			<Grid container style={{ padding: 15 }}>
				<Grid item sm={12} xs={12}>
					<Box>
						<h2>VIEW OPTIONS</h2>
					</Box>
				</Grid>
				<Grid item sm={12} xs={12}>
					<Box>Select the IEPs which you would like to view</Box>
				</Grid>
				<Grid item sm={12}>
					<FormGroup row>
						<Grid container>
							{iepList &&
								iepList.map((iep) => {
									return (
										<Grid item sm={6} key={iep.id_}>
											<FormControlLabel
												control={
													<Checkbox
														checked={iep.checked}
														onChange={(event) => {
															handleCheckChange(event, iep.id_)
														}}
														value={iep.id_}
														color="primary"
													/>
												}
												label={iep.name}
											/>
										</Grid>
									)
								})}
						</Grid>
					</FormGroup>
				</Grid>
				<Grid item sm={12}></Grid>
				<Grid item sm={6}></Grid>
				<Grid item sm={6}>
					<Button onClick={handleClose()} variant="outlined" color="primary" style={{ margin: 5 }}>
						Cancel
					</Button>
					<Button
						onClick={() => {
							props.handleSetIEPFilter(selectedIEPs)
							props.isClosed(true)
						}}
						variant="contained"
						color="primary"
						style={{ margin: 5 }}>
						Apply
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}
