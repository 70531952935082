import DateFnsUtils from "@date-io/date-fns"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import type { Moment } from "moment"
import React from "react"
import {
	getIepList,
	getPoolList,
	getRate,
	getSslList,
	getTerminals,
	newRate,
	updateRate,
} from "../api"
import WaitPopup from "./WaitPopup"

const moment = require("moment")

interface Props {
	id: number
	handleClose: Function
	newState: boolean
}

export default function (props: Props) {
	const [rate, setRate] = React.useState<any | undefined>()
	const [terminals, setTerminals] = React.useState<any | undefined>()
	const [ieps, setIeps] = React.useState<any | undefined>()
	const [pools, setPools] = React.useState<any | undefined>()
	const [ssls, setSsls] = React.useState<any | undefined>()
	const [ssl, setSsl] = React.useState(-1)
	const [iep, setIep] = React.useState(-1)
	const [pool, setPool] = React.useState(-1)
	const [terminal, setTerminal] = React.useState(-1)
	const [region, setRegion] = React.useState<any | undefined>("")
	const [curRate, setCurRate] = React.useState<any | undefined>(0)
	const [curRateDt, setCurRateDt] = React.useState<Moment | undefined>(null)
	const [upRate, setUpRate] = React.useState<any | undefined>(0)
	const [upRateDt, setUpRateDt] = React.useState<Moment | undefined>(null)
	const [preRate, setPreRate] = React.useState<any | undefined>(0)
	const [preRateDt, setPreRateDt] = React.useState<Moment | undefined>(null)

	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (rate) {
			setIep(rate.iep_id)
			setRegion(rate.region)
			setPool(rate.pool_id)
			setTerminal(rate.terminal_id)
			setSsl(rate.ssl_id)
			setCurRate(rate.current_rate)
			if (rate.effective_dt_cur) {
				setCurRateDt(moment.utc(rate.effective_dt_cur))
			}
			setUpRate(rate.upcoming_rate ? rate.upcoming_rate : 0)
			if (rate.effective_dt_up) {
				setUpRateDt(moment.utc(rate.effective_dt_up))
			}
			setPreRate(rate.previous_rate ? rate.previous_rate : 0)
			if (rate.effective_date_pre) {
				setPreRateDt(moment.utc(rate.effective_date_pre))
			}
		}
	}, [rate])

	function loadPageInfo() {
		if (props.newState) {
			setRate({
				iep_id: null,
				region: "",
				pool_id: null,
				terminal_id: null,
				ssl_id: null,
				current_rate: 0,
				effective_dt_cur: null,
				upcoming_rate: 0,
				effective_dt_up: null,
				previous_rate: 0,
				effective_date_pre: null,
			})
		} else {
			getRate(props.id, setRate)
		}

		getPoolList(setPools)
		getSslList(setSsls)
		getTerminals(setTerminals)
		getIepList(setIeps)
	}

	function handleTerminalDDChange(event: any) {
		setTerminal(event.target.value)
	}

	function handleIEPDDChange(event: any) {
		setIep(event.target.value)
	}

	function handlePoolDDChange(event: any) {
		setPool(event.target.value)
	}

	function handleSslDDChange(event: any) {
		setSsl(event.target.value)
	}

	const handleCurRateChange = (event: any) => {
		event.target.value = event.target.value.match(/[0-9]+\.?[0-9]?[0-9]?/)
		setCurRate(event.target.value)
	}

	const handleUpRateChange = (event: any) => {
		event.target.value = event.target.value.match(/[0-9]+\.?[0-9]?[0-9]?/)
		setUpRate(event.target.value)
	}

	const handlePreRateChange = (event: any) => {
		event.target.value = event.target.value.match(/[0-9]+\.?[0-9]?[0-9]?/)
		setPreRate(event.target.value)
	}

	const handleCurRateDateChange = (date: Moment | null) => {
		setCurRateDt(date)
	}

	const handleUpRateDateChange = (date: Moment | null) => {
		setUpRateDt(date)
	}

	const handlePreRateDateChange = (date: Moment | null) => {
		setPreRateDt(date)
	}

	function handleSave() {
		if (props.newState) {
			rate.iep_id = iep
			rate.region = region
			rate.pool_id = pool
			rate.terminal_id = terminal
			rate.ssl_id = ssl
			rate.current_rate = curRate
			rate.effective_dt_cur = curRateDt ? moment.utc(curRateDt.toLocaleString()).format("L") : null
			rate.upcoming_rate = upRate
			rate.effective_dt_up = upRateDt ? moment.utc(upRateDt.toLocaleString()).format("L") : null
			rate.previous_rate = preRate
			rate.effective_date_pre = preRateDt
				? moment.utc(preRateDt.toLocaleString()).format("L")
				: null
			newRate(rate, props.handleClose)
		} else {
			rate.iep_id = iep
			rate.region = region
			rate.pool_id = pool
			rate.terminal_id = terminal
			rate.ssl_id = ssl
			rate.current_rate = curRate
			rate.effective_dt_cur = curRateDt ? moment.utc(curRateDt.toLocaleString()).format("L") : null
			rate.upcoming_rate = upRate
			rate.effective_dt_up = upRateDt ? moment.utc(upRateDt.toLocaleString()).format("L") : null
			rate.previous_rate = preRate
			rate.effective_date_pre = preRateDt
				? moment.utc(preRateDt.toLocaleString()).format("L")
				: null
			updateRate(rate, props.handleClose)
		}
	}

	if (rate) {
		openWait = false
	}

	return (
		<div>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Grid container>
				<Grid item sm={12}>
					<Box>{props.newState ? <h3>Add Rate</h3> : <h3>Edit Rate</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								style={{ minWidth: 100 }}
								size="small"
								select
								variant="outlined"
								label="PROVIDER"
								value={iep}
								onChange={handleIEPDDChange}>
								{ieps &&
									ieps.map((item, index) => {
										if (ieps.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.abbrv}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={6} style={{ margin: 10 }}>
							<TextField
								label="REGION"
								type="REGION"
								value={region}
								onChange={(event: any) => {
									setRegion(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={4} style={{ margin: 10 }}>
							<TextField
								size="small"
								select
								variant="outlined"
								label="POOL"
								value={pool}
								onChange={handlePoolDDChange}>
								{pools &&
									pools.map((item, index) => {
										if (pools.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.pool_name}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={4}>
							<TextField
								style={{ minWidth: 100 }}
								size="small"
								select
								variant="outlined"
								label="TERMINAL"
								value={terminal}
								onChange={handleTerminalDDChange}>
								{terminals &&
									terminals.map((item, index) => {
										if (terminals.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.abbrv}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={3}>
							<TextField
								size="small"
								select
								variant="outlined"
								label="SSL"
								value={ssl}
								onChange={handleSslDDChange}>
								{ssls &&
									ssls.map((item, index) => {
										if (ssls.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.abbrv}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="CURRENT RATE"
								value={curRate}
								onChange={handleCurRateChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									margin="normal"
									id="date-picker-inline"
									label="EFFECTIVE DATE"
									value={curRateDt}
									style={{ width: "100%" }}
									onChange={handleCurRateDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="UPCOMING RATE"
								value={upRate}
								onChange={handleUpRateChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									margin="normal"
									id="date-picker-inline"
									label="EFFECTIVE DATE"
									value={upRateDt}
									style={{ width: "100%" }}
									onChange={handleUpRateDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="PREVIOUS RATE"
								value={preRate}
								onChange={handlePreRateChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									margin="normal"
									id="date-picker-inline"
									label="EFFECTIVE DATE"
									value={preRateDt}
									style={{ width: "100%" }}
									onChange={handlePreRateDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										props.handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary">
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
