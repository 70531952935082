import Typography from "@material-ui/core/Typography"
import * as React from "react"
import Layout from "../components/layout/Layout"

export default function NotFoundPage() {
	return (
		<Layout>
			<Typography variant="h1">Page not found</Typography>
		</Layout>
	)
}
