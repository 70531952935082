import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import { markTerminalResponseRead } from "../api"
import { formatDateMMDDYYYY } from "../lib/formatters"

const useStyles = makeStyles((theme: Theme) => createStyles({}))
interface Props {
	terminalData: any[]
}

export default function (props: Props) {
	const classes = useStyles()
	const history = useHistory()

	const handleButtonLinkClick = (invoice_id) => {
		markTerminalResponseRead(invoice_id, () => {
			history.push("/invoiceworkflow/" + invoice_id)
		})
	}

	return (
		<Grid
			container
			style={{
				maxWidth: 300,
			}}>
			<Grid item sm={12}>
				<Box
					style={{
						margin: 10,
					}}>
					<h4>TERMINAL INVOICE RESPONSES</h4>
				</Box>
			</Grid>
			<Grid item sm={12}>
				{props.terminalData && props.terminalData.length > 0 ? (
					props.terminalData.map((row) => {
						return (
							<Grid container style={{ margin: 10 }}>
								<Grid item sm={12} xs={12}>
									<Box>
										<Button
											style={{ padding: 0 }}
											color="primary"
											onClick={() => {
												handleButtonLinkClick(row.id_)
											}}>
											<u>Invoice #{row.invoice_num ? row.invoice_num : null}</u>
										</Button>
									</Box>
								</Grid>
								<Grid item sm={12} xs={12}>
									<Box>Terminal: {row.terminal_name ? row.terminal_name : null}</Box>
								</Grid>
								<Grid item sm={12} xs={12}>
									<Box>IEP Name: {row.iep_name ? row.iep_name : null}</Box>
								</Grid>
								<Grid item sm={12} xs={12}>
									<Box>
										Date Responce:{" "}
										{row.terminal_updated_dt
											? formatDateMMDDYYYY(new Date(row.terminal_updated_dt.replace(" GMT", "")))
											: null}
									</Box>
								</Grid>
							</Grid>
						)
					})
				) : (
					<Box style={{ margin: 10 }}>No Records available</Box>
				)}
			</Grid>
		</Grid>
	)
}
