export interface DataAPInvoices {
	invoices_id: string
	invoice_num: string
	n_days_old: string
	total_billed: string
	pending_payments: string
	past_payments: string
	disputed: string
	credits: string
	reconciliation: string
}

export const HeaderDataAPInvoices: HeadCell[] = [
	{ id: "invoice_num", numeric: false, disablePadding: true, label: "INVOICE #" },
	{ id: "n_days_old", numeric: false, disablePadding: false, label: "AGE" },
	{ id: "total_billed", numeric: false, disablePadding: false, label: "TOTAL BILLED" },
	{ id: "pending_payments", numeric: false, disablePadding: false, label: "PENDING PAYMENTS" },
	{ id: "past_payments", numeric: false, disablePadding: false, label: "PAST PAYMENTS" },
	{ id: "disputed", numeric: false, disablePadding: false, label: "DISPUTED" },
	{ id: "credits", numeric: false, disablePadding: false, label: "CREDITS" },
	{ id: "reconciliation", numeric: false, disablePadding: false, label: "INVOICE RECONCILIATION" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataAPInvoices
	label: string
	numeric: boolean
}
