import { Box, Paper, Typography } from "@material-ui/core"
import type * as React from "react"

interface Props {
	title?: string
}

export default function Panel(props: React.PropsWithChildren<Props>) {
	return (
		<Paper square elevation={0} style={{ boxShadow: "0px 3px 6px #00000029" }}>
			<Box padding={2}>
				<Typography variant="h1" color="primary">
					{props.title}
				</Typography>
			</Box>
			{props.children}
		</Paper>
	)
}
