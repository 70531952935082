export function formatNumber(num: number): string {
	if (typeof num === "number" && num === num) {
		return num
			.toFixed(2)
			.replace(/\.00$/, "")
			.replace(/\.(\d)0$/, ".$1")
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	return num + ""
}

export function formatCurrency(num: number): string {
	if (typeof num === "number" && num === num) {
		return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	return num + ""
}

// export function formatDollars(num: number): string {
// 	if (typeof num === "number" && num === num) {
// 		return (
// 			"$" +
// 			Math.round(num)
// 				.toString()
// 				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
// 		)
// 	}
// 	return num + ""
// }

// export function formatNumberOfBytes(nBytes: number): string {
// 	if (typeof nBytes !== "number" || nBytes !== nBytes) {
// 		return nBytes + ""
// 	}
// 	nBytes = Math.floor(Math.abs(nBytes))
// 	if (nBytes === 0) {
// 		return "0 B"
// 	}
// 	const sizes = ["B", "KB", "MB", "GB", "TB"]
// 	const i = Math.min(sizes.length - 1, Math.floor(Math.log(nBytes) / Math.log(1024)))
// 	return (
// 		Math.round(nBytes / Math.pow(1024, i))
// 			.toString()
// 			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
// 		" " +
// 		sizes[i]
// 	)
// }

export function formatDateMMDDYYYY(date: Date, withDashes?: boolean) {
	if (date && typeof date === "string") {
		date = new Date(date)
	}

	const withoutTimezone = new Date(date.valueOf()).toISOString().slice(0, -1)

	date = new Date(withoutTimezone)

	if (withDashes) {
		return date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
	}

	return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
}

export function formatDateMMDDYYYYAndTime(date: Date) {
	return date.toLocaleString()
}

export function formatPascalToString(text: string) {
	var wordList = []
	var phrase = ""
	if (text) {
		wordList = text.match(/[A-Z][a-z]+/g)
		wordList.forEach((word) => {
			phrase += " " + word
		})
	}
	return phrase
}
