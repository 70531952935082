import { Box, Link, type Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import * as React from "react"
import type { UWLTableColumn } from "../../types/UWLTable"
import { UWLTable } from "../CustomTable/UWLTable"
import RouterLink from "../RouterLink"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		link: {
			color: theme.palette.primary.light,
			fontSize: "0.875rem",
			textDecoration: "underline",
			"&:hover": {
				cursor: "pointer",
				color: theme.palette.secondary.main,
				transition: "0.3s",
			},
		},
	}),
)

interface Props {
	invoices: any | undefined
	loading: boolean
}

export default function InvoicesToReview(props: Props) {
	const [invoices, setInvoices] = React.useState([])
	const classes = useStyles()

	const columns: UWLTableColumn<any>[] = [
		{ id: "invoice", label: "Invoice #", type: "string" },
		{ id: "daysRemaining", label: "Days Remaining", type: "number" },
		{ id: "totalInvoices", label: "Completed", type: "string" },
		{ id: "status", label: "Status", type: "string" },
	]

	React.useEffect(() => {
		if (props.invoices) {
			setInvoices(props.invoices)
		}
	}, [props.invoices])

	return (
		<Box padding={2}>
			<UWLTable
				rowId="invoice"
				columns={columns}
				sizeMedium
				isLoading={props.loading}
				rows={invoices}
				emptyMessage="No items to show"
				renderCell={{
					invoice(row) {
						return (
							<Link
								color="secondary"
								underline="always"
								className={classes.link}
								component={RouterLink}
								to={"/approveInvoice/" + row.id}>
								{row.invoice}
							</Link>
						)
					},
					totalInvoices(row) {
						return (
							<Box>
								{row.reviewedInvoices} / {row.totalInvoices}
							</Box>
						)
					},
					status(row) {
						return (
							<Typography variant="body1">
								{row.reviewedInvoices === row.totalInvoices ? "Completed" : "In Progress"}
							</Typography>
						)
					},
				}}
			/>
		</Box>
	)
}
