function isDate(v: any): v is Date {
	if (v instanceof Date) {
		const time = v.getTime()
		if (
			time === time // check that it's not NaN (NOTE: IE doesn't support Number.isNan)
		) {
			return true
		}
	}
	return false
}

export function fromAPI_Date(v: any): Date | null {
	if (typeof v === "string") {
		if (
			v.length === 10 // yyyy-mm-dd
		) {
			v = new Date(v + "T00:00:00Z")
		} else if (
			v.length === 19 // yyyy-mm-ddThh:mm:ss
		) {
			v = new Date(v + "Z")
		} else {
			v = new Date(v)
		}
	}
	if (isDate(v)) {
		return v
	}
	return null
}

// export function fromAPI_DateNotLocalized(v: any): Date | null {
// 	const date = fromAPI_Date(v)
// 	if (date) {
// 		date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
// 		return date
// 	}
// 	return null
// }

function parseMonth(src: string): number | null {
	switch (src[0]) {
		case "j":
			if (src[1] === "a") {
				return 1
			} else if (src.indexOf("l") >= 0) {
				return 7
			} else {
				return 6
			}
		case "f":
			return 2
		case "m":
			if (src.indexOf("y") >= 0) {
				return 5
			} else {
				return 3
			}
		case "a":
			return 8
		case "s":
			return 9
		case "o":
			return 10
		case "n":
			return 11
		case "d":
			return 12
	}
	return null
}

function makeDate(year: number, month: number, day: number): Date {
	return new Date(year, month - 1, day)
}

const parseDateFormats: {
	regex: RegExp
	parse(m: RegExpExecArray, year: number, month: number, day: number): Date | null
}[] = [
	{
		regex: /^([0-9][0-9][0-9][0-9]) ([0-9][0-9]?) ([0-9][0-9]?)$/,
		parse(m, year, month, day) {
			year = Number.parseInt(m[1], 10) || 0
			month = Number.parseInt(m[2], 10) || 0
			day = Number.parseInt(m[3], 10) || 0
			return makeDate(year, month, day)
		},
	},
	{
		regex: /^([0-9][0-9]?) ([0-9][0-9]?)$/,
		parse(m, year, month, day) {
			month = Number.parseInt(m[1], 10) || 0
			day = Number.parseInt(m[2], 10) || 0
			return makeDate(year, month, day)
		},
	},
	{
		regex: /^([0-9][0-9]?) ([0-9][0-9]?) ([0-9][0-9][0-9][0-9])$/,
		parse(m, year, month, day) {
			month = Number.parseInt(m[1], 10) || 0
			day = Number.parseInt(m[2], 10) || 0
			year = Number.parseInt(m[3], 10) || 0
			return makeDate(year, month, day)
		},
	},
	{
		regex: /^([0-9][0-9]?) ([0-9][0-9]?) ([0-9][0-9])$/,
		parse(m, year, month, day) {
			month = Number.parseInt(m[1], 10) || 0
			day = Number.parseInt(m[2], 10) || 0
			year = 2000 + Number.parseInt(m[3], 10) || 0
			return makeDate(year, month, day)
		},
	},
	{
		regex: /^([a-z]+) ?([0-9][0-9]?)$/,
		parse(m, year, month, day) {
			const pMonth = parseMonth(m[1])
			if (!pMonth) {
				return null
			}
			month = pMonth
			day = Number.parseInt(m[2], 10) || 0
			return makeDate(year, month, day)
		},
	},
	{
		regex: /^([a-z]+) ?([0-9][0-9]?) ([0-9][0-9][0-9][0-9])$/,
		parse(m, year, month, day) {
			const pMonth = parseMonth(m[1])
			if (!pMonth) {
				return null
			}
			month = pMonth
			day = Number.parseInt(m[2], 10) || 0
			year = Number.parseInt(m[3], 10) || 0
			return makeDate(year, month, day)
		},
	},
	{
		regex: /^([a-z]+) ?([0-9][0-9]?) ([0-9][0-9])$/,
		parse(m, year, month, day) {
			const pMonth = parseMonth(m[1])
			if (!pMonth) {
				return null
			}
			month = pMonth
			day = Number.parseInt(m[2], 10) || 0
			year = 2000 + Number.parseInt(m[3], 10) || 0
			return makeDate(year, month, day)
		},
	},
]

// export function parseDate(src?: string | null): Date | null {
// 	if (typeof src !== "string") {
// 		if (isDate(src)) {
// 			return src
// 		}
// 		return null
// 	}
// 	src = src
// 		.toLocaleLowerCase()
// 		.replace(/[^a-z0-9]+/g, " ")
// 		.trim()

// 	const d = new Date()
// 	const year = d.getFullYear()
// 	const month = d.getMonth() + 1
// 	const day = d.getDate()

// 	for (const f of parseDateFormats) {
// 		const m = f.regex.exec(src)
// 		if (m) {
// 			return f.parse(m, year, month, day)
// 		}
// 	}
// 	return null
// }

// export function toIsoDateNoTZ(d: Date): string {
// 	// Not using .toISOString() b/c it converts the datetime to UTC
// 	return `${d.getFullYear()}-${(d.getMonth() + 1 + "").padStart(2, "0")}-${(d.getDate() + "").padStart(2, "0")}`
// }

// export function toAPI_Date(d: Date) {
// 	// Not using .toISOString() b/c it converts the datetime to UTC
// 	return `${d.getFullYear()}-${(d.getMonth() + 1 + "").padStart(2, "0")}-${(d.getDate() + "").padStart(2, "0")}`
// }

// export function toAPI_DateTime(d: Date) {
// 	return d.toISOString()
// }
