export interface DataCustomerRulesManager {
	id_: number
	ssl_code: string
	ssl_contact_info: string
	pool_code: string
	terminal_code: string
	billing_party: string
	canned_response_reason: string
	consignee: string
	contract_num: string
	customer_bill_to_name: string
	delivery_location: string
	ecm_comments: string
	end_date: Date
	for_terminal_nam: string
	import_export: string
}

export const HeaderDataCustomerRulesManager: HeadCell[] = [
	{ id: "terminal_code", numeric: false, disablePadding: false, label: "TERMINAL" },
	{ id: "customer_bill_to_name", numeric: false, disablePadding: false, label: "CUSTOMER BILL TO" },
	{ id: "ssl_code", numeric: false, disablePadding: false, label: "SSL CODE" },
	{ id: "pool_code", numeric: false, disablePadding: false, label: "POOL" },

	{ id: "billing_party", numeric: false, disablePadding: false, label: "BILLING PARTY" },
	{ id: "consignee", numeric: false, disablePadding: false, label: "CONSIGNEE" },
	{ id: "import_export", numeric: false, disablePadding: false, label: "IMPORT/EXPORT" },
	{ id: "delivery_location", numeric: false, disablePadding: false, label: "DELIVERY LOCATION" },
	{ id: "end_date", numeric: false, disablePadding: false, label: "END DATE" },
	{ id: "for_terminal_nam", numeric: false, disablePadding: false, label: "FOR TERMINAL/NAM" },
	{ id: "contract_num", numeric: false, disablePadding: false, label: "CONTRACT #" },
	{ id: "ssl_contact_info", numeric: false, disablePadding: false, label: "CONTACT" },
	{ id: "ecm_comments", numeric: false, disablePadding: false, label: "ECM COMMENTS" },
	{
		id: "canned_response_reason",
		numeric: false,
		disablePadding: false,
		label: "CANNED RESPONSE",
	},
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataCustomerRulesManager
	label: string
	numeric: boolean
}
