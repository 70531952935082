import { type SortDirection, sortWithBy, stableSort } from "../sort"

/**
 * Which columns are visible, and how to order them
 */
export interface UWLTableColumnDisplay<T extends {}> {
	id: Extract<keyof T, string>
	visible: boolean
	order: number
}

/**
 * This is how columns are defined
 */
interface UWLTableColumn_base<T extends {}> {
	id: Extract<keyof T, string>
	label: string
	labelTitle?: string
	sortWith?: (a: T, b: T) => number
}

interface UWLTableColumn_string<T extends {}> extends UWLTableColumn_base<T> {
	type: "string"
	options?: { value: string; label: string }[]
}

interface UWLTableColumn_icon<T extends {}> extends UWLTableColumn_base<T> {
	type: "icon"
	options?: { value: string }[]
}

interface UWLTableColumn_currency<T extends {}> extends UWLTableColumn_base<T> {
	type: "currency"
	currencyField?: Extract<keyof T, string>
}

interface UWLTableColumn_weight<T extends {}> extends UWLTableColumn_base<T> {
	type: "weight"
	unitField?: Extract<keyof T, string>
}

interface UWLTableColumn_volume<T extends {}> extends UWLTableColumn_base<T> {
	type: "volume"
	unitField?: Extract<keyof T, string>
}

interface UWLTableColumn_date<T extends {}> extends UWLTableColumn_base<T> {
	type: "date"
}

interface UWLTableColumn_datetime<T extends {}> extends UWLTableColumn_base<T> {
	type: "datetime"
}

interface UWLTableColumn_number<T extends {}> extends UWLTableColumn_base<T> {
	type: "number"
}

export type UWLTableColumn<T> =
	| UWLTableColumn_string<T>
	| UWLTableColumn_currency<T>
	| UWLTableColumn_weight<T>
	| UWLTableColumn_volume<T>
	| UWLTableColumn_date<T>
	| UWLTableColumn_datetime<T>
	| UWLTableColumn_number<T>
	| UWLTableColumn_icon<T>

export function toVisibleColumnsInOrder<T>(
	columns: UWLTableColumn<T>[],
	columnsDisplay?: UWLTableColumnDisplay<T>[],
): UWLTableColumn<T>[] {
	if (!columnsDisplay || !Array.isArray(columnsDisplay)) {
		return columns
	}
	return columns
		.map((column) => {
			const display = columnsDisplay && columnsDisplay.find((c) => c.id === column.id)
			return {
				display,
				column,
			}
		})
		.filter((col) => {
			return col.display && col.display.visible
		})
		.sort((a, b) => {
			const aOrder = a.display ? a.display.order : -1
			const bOrder = b.display ? b.display.order : -1
			return aOrder - bOrder
		})
		.map((col) => col.column)
}

export function sortedRows<T>(
	columns: UWLTableColumn<T>[],
	rows: T[],
	orderBy: keyof T | null = null,
	sortDirection: SortDirection = "desc",
): T[] {
	if (orderBy) {
		const col = columns.find((col) => col.id === orderBy)
		const sortWith = (col && col.sortWith) || sortWithBy((row) => (row as any)[orderBy])
		rows = stableSort(rows, sortWith, sortDirection)
	}
	return rows
}

