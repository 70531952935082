import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useStyles } from "./styles"

export default function (columns: any, reportName: any) {
	const classes = useStyles()
	const previewRows = Array(20)

	React.useEffect(() => {}, [])

	React.useEffect(() => {
		for (var i = 0; i < previewRows.length; i++) {
			previewRows[i] = i
		}
	}, [columns])

	const getHeaderStyle = (index) => ({
		borderBottom: "1px solid grey",
		fontSize: "10pt",
		borderLeft: index == 0 ? "0px solid grey" : "1px solid grey",
		backgroundColor: "white",
		color: "#5A5A5A",
		margin: 1,
	})

	const getRowStyle = (index) => ({
		borderBottom: "0px solid grey",
		fontSize: "10pt",
		borderLeft: index == 0 ? "0px solid grey" : "1px solid grey",
		backgroundColor: "white",
		color: "#8D8D8D",
		margin: 1,
	})

	return (
		<Container style={{ height: "100%", width: "100%" }}>
			<Paper variant="elevation" style={{ height: "100%", width: "99%", padding: 12 }}>
				<Typography
					paragraph
					variant="h3"
					align="center"
					style={{ color: "#5A5A5A", fontWeight: "normal", padding: 15, marginBottom: 10 }}>
					{columns.reportName}
				</Typography>

				<TableContainer style={{ width: "100%" }}>
					<Table id="" stickyHeader={true} size={"small"}>
						<TableHead>
							<TableRow>
								{columns.columns &&
									columns.columns.map((column, index) => {
										return (
											<TableCell
												key={column.name + column.sequence.toString()}
												align={"left"}
												style={getHeaderStyle(column.sequence)}>
												{column.label}
											</TableCell>
										)
									})}
							</TableRow>
						</TableHead>

						<TableBody>
							{[...previewRows].map((value, index) => {
								return (
									<TableRow key={value + index.toString()}>
										{columns.columns &&
											columns.columns.map((column, index) => {
												return (
													<TableCell
														key={column.name + index.toString() + column.sequence.toString()}
														align={"left"}
														style={getRowStyle(column.sequence)}>
														{column.label}
													</TableCell>
												)
											})}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<Typography
					align="center"
					variant="h3"
					style={{ backgroundColor: "transparent", boxShadow: "none" }}></Typography>
			</Paper>
		</Container>
	)
}
