import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { updateCCMRecord } from "../../../api"
import { formatDateMMDDYYYY, formatDateMMDDYYYYAndTime } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import RecordSearch from "../../RecordSearch"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type DataCCMWorkflow, type HeadCell, HeaderDataInvoiceRecons } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataCCMWorkflow) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
	viewFirstColumn: boolean
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const {
		classes,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		headCells,
		viewFirstColumn,
	} = props
	const createSortHandler =
		(property: keyof DataCCMWorkflow) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{viewFirstColumn && (
					<TableCell
						padding={"checkbox"}
						style={{
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}

				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

function rand() {
	return Math.round(Math.random() * 10) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()

	return {
		top: `${10}%`,
		left: `${3}%`,
		overflow: "auto",
	}
}

interface Props {
	ccmData: any[]
	iepList: any[]
	statusList: any[]
	viewFirstColumn: boolean
	disableControls?: boolean
	maxHeight?: number
	displayAllItems?: boolean
}

export default function (props: Props) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("desc")
	const [orderBy, setOrderBy] = React.useState<string>("updated_dt")
	const [selectedItem, setSelected] = React.useState<number>()
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(
		props.displayAllItems ? props.ccmData.length : 200,
	)
	const [openModal, setOpenModal] = React.useState(false)
	const [modalStyle] = React.useState(getModalStyle)
	const [manifestSelected, setManifestSelected] = React.useState<any | undefined>()
	const [manifestUnselected, setManifestUnselected] = React.useState<any | undefined>()
	const [ccmRecordID, setCcmRecordID] = React.useState<any | undefined>()
	const [ccmRecords, setCcmRecords] = React.useState<any | undefined>([])

	React.useEffect(() => {
		const data = props.ccmData
		if (data && data.length > 0) {
			data.forEach((item) => {
				if (item.matches) {
					item.customer_name = item.matches.customer_name ? item.matches.customer_name : ""
					item.site = item.matches.site ? item.matches.site : ""
					item.ssl = item.matches.ssl ? item.matches.ssl : ""
					item.booking_bol = item.matches.booking_bol ? item.matches.booking_bol : ""
				}
			})
		}
		setCcmRecords(data)
	}, [props.ccmData])

	React.useEffect(() => {
		if (manifestSelected) {
			const refObj = ccmRecords.find((row: any) => row.id_ === ccmRecordID)
			refObj.manifest_num = manifestSelected
			updateCCMRecord(refObj, (data) => {
				setManifestSelected(undefined)
				setManifestUnselected(undefined)
			})
		}
	}, [manifestSelected])

	React.useEffect(() => {
		if (manifestUnselected) {
			const refObj = ccmRecords.find((row: any) => row.id_ === ccmRecordID)
			refObj.manifest_num = null
			updateCCMRecord(refObj, (data) => {
				setManifestSelected(undefined)
				setManifestUnselected(undefined)
			})
		}
	}, [manifestUnselected])

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DataCCMWorkflow) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
		let newSelected = -1
		newSelected = name
		setSelected(newSelected)
	}

	const handlSearchClick = (event: any, name: number, id: number) => {
		handleOpen()
		setCcmRecordID(id)
	}

	const hangleDropDownChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		id: number,
	) => {
		const refList = ccmRecords
		refList.forEach((item) => {
			if (item.id_ == id) {
				if (item) {
					item.new_rp = event.target.value
					if (item.new_rp === null || item.new_rp === -1 || item.new_rp === -99) {
						item.new_rp = null
						item.record_status = props.statusList.find(
							(sl) => sl.status.toLowerCase() === "nomatched",
						).id_
					} else {
						item.record_status = props.statusList.find(
							(sl) => sl.status.toLowerCase() === "matched",
						).id_
					}
					updateCCMRecord(item, () => {})
					item.new_rp = event.target.value
				}
			}
		})
		setCcmRecords(refList)
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(Number.parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleOpen = () => {
		setOpenModal(true)
	}

	const handleClose = () => {
		setOpenModal(false)
	}

	const maxHeight = props.maxHeight ? props.maxHeight.toString() + "px" : "100%"
	if (ccmRecords) {
		return (
			<div style={{ height: "100%", width: "100%" }}>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openModal}
					onClose={handleClose}>
					<div style={modalStyle} className={classes.modal}>
						<RecordSearch
							isClosed={handleClose}
							ccmRecordID={ccmRecordID}
							assignTriniumManifest={setManifestSelected}
							unassignTriniumManifest={setManifestUnselected}
						/>
					</div>
				</Modal>
				<Box style={{ height: "auto", width: "100%", padding: 0 }}>
					<TableToExcel
						id="tte_ccmRecon"
						sheet="Worksheet"
						table="ccmRecon"
						fileName={"ccmRec_" + formatDateMMDDYYYY(new Date(), true)}
						buttonText=""
						className=""></TableToExcel>
				</Box>
				<TableContainer style={{ maxHeight: maxHeight, width: "100%" }}>
					<Table id="ccmRecon" stickyHeader={true} size={"small"}>
						<SortableTableHeaders
							classes={classes}
							numSelected={1}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={ccmRecords.length}
							headCells={HeaderDataInvoiceRecons}
							viewFirstColumn={props.viewFirstColumn}
						/>
						<TableBody>
							{stableSort(ccmRecords, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow
											key={row.id_}
											hover
											onClick={(event) => handleClick(event, index)}
											tabIndex={-1}
											selected={selectedItem === index}>
											{props.viewFirstColumn && (
												<TableCell style={{ padding: 3 }}>
													<IconButton
														onClick={(event) => handlSearchClick(event, index, row.id_)}
														disabled={props.disableControls}
														className={classes.triniumIcon}></IconButton>
												</TableCell>
											)}
											<TableCell>
												<TextField
													disabled={props.disableControls}
													size="small"
													select
													variant="outlined"
													value={row.new_rp ? row.new_rp : -99}
													onChange={(event) => hangleDropDownChange(event, row.id_)}>
													<MenuItem key={-99} value={-99}>
														{"     "}
													</MenuItem>
													<MenuItem key={-1} value={-1}>
														{"No Match"}
													</MenuItem>
													{props.iepList &&
														props.iepList.map((opt: any) => {
															return (
																<MenuItem key={opt.id_} value={opt.id_}>
																	{opt.name}
																</MenuItem>
															)
														})}
												</TextField>
											</TableCell>

											<TableCell>{row.id_}</TableCell>
											<TableCell>{row.chassis_id ? row.chassis_id.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.container_id ? row.container_id.toUpperCase() : ""}
											</TableCell>
											<TableCell>{row.move_type ? row.move_type.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.cms_move_dt
													? formatDateMMDDYYYYAndTime(new Date(row.cms_move_dt.replace(" GMT", "")))
													: ""}
											</TableCell>
											<TableCell>{row.pool_id ? row.pool_id.toUpperCase() : ""}</TableCell>
											<TableCell>{row.current_rp ? row.current_rp.toUpperCase() : ""}</TableCell>
											<TableCell>{row.current_uu ? row.current_uu.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.current_booking ? row.current_booking.toUpperCase() : ""}
											</TableCell>
											<TableCell>{row.current_bol ? row.current_bol.toUpperCase() : ""}</TableCell>
											<TableCell>
												{row.manifest_num ? row.manifest_num.toUpperCase() : ""}
											</TableCell>
											<TableCell>
												{row.customer_name ? row.customer_name.toUpperCase() : ""}
											</TableCell>
											<TableCell>{row.site ? row.site.toUpperCase() : ""}</TableCell>
											<TableCell>{row.ssl ? row.ssl.toUpperCase() : ""}</TableCell>
											<TableCell>{row.booking_bol ? row.booking_bol.toUpperCase() : ""}</TableCell>
										</TableRow>
									)
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[200]}
					component="div"
					count={ccmRecords.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			</div>
		)
	} else {
		return (
			<div>
				<h3>No Records</h3>
			</div>
		)
	}
}
