import { TextField } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Snackbar from "@material-ui/core/Snackbar"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import clonedeep from "lodash/cloneDeep"
import React from "react"
import { useHistory } from "react-router-dom"
import { getUsers } from "../api/userAPI"
import ReportColumnsPreview from "../components/CustomTable/reportColumnsPreviewTable/ReportColumnsPreview"
import Layout from "../components/layout/Layout"
import type { ReportData } from "../types/ReportData"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexWrap: "wrap",
			"& > *": {
				margin: theme.spacing(1),
				width: theme.spacing(16),
				height: theme.spacing(16),
			},
		},
	}),
)

interface Props {
	style: any
	selectedColumns: any
}

export default function (props: any) {
	const [activeColumns, setActiveColumns] = React.useState<any>([])
	const [ready, setReady] = React.useState<boolean>(false)
	const [reportData, setReportData] = React.useState<ReportData | undefined>(props.location.state)
	const [isNext, setNext] = React.useState<boolean | undefined>(false)
	const [isBack, setBack] = React.useState<boolean | undefined>(false)

	const history = useHistory()
	const [users, setUsers] = React.useState<any>([])
	const [recipients, setRecipients] = React.useState<null | any>()
	const [otherRecipient, setOtherRecipient] = React.useState<null | any>("")
	const [otherRecipients, setOtherRecipients] = React.useState<null | any>([])
	const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false)

	React.useEffect(() => {
		prepareUsers()
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (isNext) {
			if (reportData) {
				history.push("/reportreview", reportData)
			}
		}
	}, [isNext])

	React.useEffect(() => {
		if (isBack) {
			if (reportData) {
				history.push("/reportfilters", reportData)
			}
		}
	}, [isBack])

	React.useEffect(() => {
		if (recipients && recipients.length > 0) {
			setReady(true)
		} else if (!reportData.isScheduled) {
			setReady(true)
		} else {
			setReady(false)
		}
	}, [recipients])

	React.useEffect(() => {
		if (users && users.length > 0 && recipients && recipients.length > 0) {
			separateOtherRecipients(recipients)
		}
	}, [users])

	function checkActive(user) {
		if (recipients && recipients.length > 0) {
			let activeFlag = false
			recipients.forEach((rec) => {
				if (rec == user.email) {
					activeFlag = true
				}
			})
			return activeFlag
		}
	}

	const loadPageInfo = () => {
		let recs
		const tempRecs = []
		if (props.location.state.recipients) {
			recs = clonedeep(props.location.state.recipients)

			recs.forEach((rec) => {
				let email = ""
				if (rec.email_address) {
					email = rec.email_address
				} else {
					email = rec
				}
				tempRecs.push(email)
			})
			setRecipients(tempRecs)
			deduplicateRecipients()
		} else {
			setRecipients([])
		}
		if (!reportData.isScheduled) {
			setReady(true)
		}
	}

	const prepareUsers = () => {
		getUsers(setUsers)
	}

	function capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	function isEmail(email) {
		return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)
	}

	const closeEmailPopup = () => {
		setInvalidEmail(false)
	}

	const handleAdd = () => {
		emailValidationOnOtherRecip(otherRecipient)
	}

	const emailValidationOnOtherRecip = (otherRecipient) => {
		const tempRecipients = clonedeep(recipients)
		const tempOthers = clonedeep(otherRecipients)
		if (isEmail(otherRecipient)) {
			setInvalidEmail(false)
			tempRecipients.push(otherRecipient.toLowerCase())
			tempOthers.push(otherRecipient.toLowerCase())
			setRecipients(tempRecipients)
			setOtherRecipients(tempOthers)
			setOtherRecipient("")
			deduplicateRecipients()
		} else {
			setInvalidEmail(true)
		}
	}

	const separateOtherRecipients = (recipientList) => {
		const tempRecipients = clonedeep(recipients)
		const tempUsers = []
		const tempOthers = []
		users.forEach((user) => {
			tempUsers.push(user.email)
		})
		tempRecipients.forEach((rec) => {
			if (tempUsers.includes(rec)) {
			} else {
				tempOthers.push(rec)
			}
		})
		setOtherRecipients(tempOthers)
	}

	function deduplicateRecipients() {
		const recSet = new Set(clonedeep(recipients))
		const noDupArray = Array.from(recSet)
		return noDupArray
	}

	const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		const tempRecipients = clonedeep(recipients)
		if (event.target.checked) {
			tempRecipients.push(event.target.name)
		} else {
			for (var i = 0; i < tempRecipients.length; i++) {
				if (tempRecipients[i] === event.target.name) {
					tempRecipients.splice(i, 1)
				}
			}
		}

		setRecipients(tempRecipients)
	}

	function handleNext() {
		const tempRecipients = deduplicateRecipients()

		setRecipients(tempRecipients)

		setReportData({
			allColumns: reportData.allColumns,
			existingReport: reportData.existingReport,
			reportName: reportData.reportName,
			isScheduled: reportData.isScheduled,
			isWeekly: reportData.isWeekly,
			category: reportData.category,
			scheduledTime: reportData.scheduledTime,
			scheduledStartDate: reportData.scheduledStartDate,
			weekdays: reportData.weekdays,
			frequency: reportData.frequency,
			activeColumns: reportData.activeColumns,
			filters: reportData.filters,
			recipients: recipients,
			username: "",
			reportID: reportData.reportID,
		})
		setNext(true)
	}

	function handleBack() {
		const tempRecipients = deduplicateRecipients()

		setRecipients(tempRecipients)

		setReportData({
			allColumns: reportData.allColumns,
			existingReport: reportData.existingReport,
			reportName: reportData.reportName,
			isScheduled: reportData.isScheduled,
			isWeekly: reportData.isWeekly,
			category: reportData.category,
			scheduledTime: reportData.scheduledTime,
			scheduledStartDate: reportData.scheduledStartDate,
			weekdays: reportData.weekdays,
			frequency: reportData.frequency,
			activeColumns: reportData.activeColumns,
			filters: reportData.filters,
			recipients: recipients,
			username: "",
			reportID: reportData.reportID,
		})
		setBack(true)
	}

	return (
		<Layout>
			<Box>
				<Paper style={{ backgroundColor: "transparent" }}>
					<Grid container style={{ backgroundColor: "transparent", padding: 5 }}>
						<Grid item sm={6}>
							<Grid style={{ maxHeight: 700, overflow: "scroll" }} item sm={12}>
								<Typography style={{ marginLeft: 16 }}>RECIPIENTS</Typography>
								<hr></hr>
								<Typography style={{ margin: 12, color: "#368BCC" }} variant="body2">
									Users
								</Typography>
								<TableContainer>
									<Table id="" stickyHeader={true} size={"small"}>
										<TableHead></TableHead>
										<TableBody>
											{users &&
												users.length > 0 &&
												users.map((user, index) => {
													const active = checkActive(user)
													if (user.name != "System") {
														return (
															<TableRow
																style={{ padding: 2, margin: 2 }}
																key={user.name + user.email}>
																<TableCell style={{ padding: 2, margin: 2 }}>
																	<Checkbox
																		name={user.email}
																		onChange={handleCheck}
																		checked={active}
																	/>
																</TableCell>
																<TableCell
																	style={{ padding: 2, margin: 2 }}
																	key={"name" + user.name}>
																	{user.name}
																</TableCell>
																<TableCell
																	style={{ padding: 2, margin: 2 }}
																	key={"email" + user.email}>
																	{user.email}
																</TableCell>
															</TableRow>
														)
													}
												})}
											{otherRecipients &&
												otherRecipients.map((recipient, index) => {
													return (
														<TableRow
															style={{ padding: 2, margin: 2 }}
															key={index.toString() + recipient}>
															<TableCell style={{ padding: 2, margin: 2 }}>
																<Checkbox defaultChecked name={recipient} onChange={handleCheck} />
															</TableCell>
															<TableCell
																style={{ padding: 2, margin: 2 }}
																key={"name" + index.toString() + recipient}>
																{}
															</TableCell>
															<TableCell
																style={{ padding: 2, margin: 2 }}
																key={"email" + index.toString() + recipient}>
																{recipient}
															</TableCell>
														</TableRow>
													)
												})}
										</TableBody>
									</Table>
								</TableContainer>
								<Typography style={{ margin: 16, color: "#368BCC" }} variant="body2">
									Other Recipients
								</Typography>
								<Grid container style={{ margin: 16 }}>
									<Grid item sm={9} style={{ margin: 4 }}>
										<TextField
											fullWidth
											size="small"
											type="email"
											onChange={(event: any) => {
												setOtherRecipient(event.target.value)
											}}
											variant="outlined"
											label="Email"
											InputLabelProps={{ shrink: true }}
											value={otherRecipient}></TextField>
										<Snackbar
											autoHideDuration={3000}
											open={invalidEmail}
											onClose={closeEmailPopup}
											message="Invalid email address format."
											key={"invalidEmail"}
										/>
									</Grid>
									<Grid item sm={2} style={{ margin: 4 }}>
										<Button
											onClick={handleAdd}
											variant="outlined"
											style={{
												color: "white",
												backgroundColor: "#368BCC",
												width: "80%",
												height: "100%",
											}}>
											ADD
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="center">
								<Grid item sm={3}>
									<Button
										variant="outlined"
										color="primary"
										style={{ margin: 10, float: "left", width: "80%" }}
										onClick={(e) => {
											handleBack()
										}}>
										Back
									</Button>
								</Grid>
								<Grid item sm={6}>
									<hr color="#909090" />
								</Grid>
								<Grid item sm={3}>
									<Button
										disabled={!ready}
										variant="outlined"
										color="primary"
										style={{ margin: 10, float: "right", width: "80%" }}
										onClick={(e) => {
											handleNext()
										}}>
										Next
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={6} style={{ maxHeight: 750, overflow: "hidden" }}>
							{activeColumns && props.location.state.reportName && (
								<ReportColumnsPreview
									columns={reportData.activeColumns}
									reportName={reportData.reportName}
									category={reportData.category}
									isScheduled={reportData.isScheduled}
									isWeekly={reportData.isWeekly}
									scheduledTime={reportData.scheduledTime}
									scheduledStartDate={reportData.scheduledStartDate}
									weekdays={reportData.weekdays}
								/>
							)}
						</Grid>
					</Grid>
				</Paper>
			</Box>
		</Layout>
	)
}
