export interface DataPoolManager {
	id_: number
	pool_name: string
	iep_name: string
	pool_code: string
}

export const HeaderDataPoolManager: HeadCell[] = [
	{
		id: "pool_name",
		numeric: false,
		disablePadding: false,
		label: "NAME",
	},
	{ id: "pool_code", numeric: false, disablePadding: false, label: "ABBREVIATION" },
	{ id: "iep_name", numeric: false, disablePadding: false, label: "IEP" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataPoolManager
	label: string
	numeric: boolean
}
