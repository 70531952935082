import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import React from "react"
import { getInvoicesAP } from "../api"
import APSummaryDashTable from "../components/CustomTable/apSummaryDashTable/APSummaryDashTable"
import ErrorMessage from "../components/ErrorMessage"
import LoadingProgress from "../components/LoadingProgress"
import Layout from "../components/layout/Layout"
import useAsyncLoader from "../lib/useAsyncLoader"

type Props = {}

export default function (props: Props) {
	const invoicesAPLoader = useAsyncLoader<any[]>([], getInvoicesAP)

	React.useEffect(() => {
		invoicesAPLoader.load()
	}, [])

	const apInvoices: any[] = invoicesAPLoader.data

	return (
		<Layout>
			<Paper style={{ maxHeight: 700 }}>
				<Box style={{ margin: 10, height: "100%" }}>
					<Grid container style={{ padding: 15 }}>
						<Grid item sm={12} style={{ height: 20 }}>
							<Grid container>
								<Grid item sm={10}>
									<Box>
										<h4>INVOICE APs ({apInvoices.length})</h4>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={12}>
							<ErrorMessage error={invoicesAPLoader.error} />
							{invoicesAPLoader.waiting ? (
								<LoadingProgress />
							) : apInvoices.length > 0 ? (
								<APSummaryDashTable invoiceData={apInvoices} maxHeight={600} />
							) : (
								<Box style={{ marginTop: 20 }}>
									<div>No Records available</div>
								</Box>
							)}
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
