import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: "100%",
		},
		paper: {
			position: "absolute",
			width: 250,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		table: {
			minWidth: 750,
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1,
		},
		triniumIcon: {
			background: "url(" + require("../../../img/icons/image61.png") + ") no-repeat center",
			backgroundSize: "contain",
		},
		check: {
			color: "rgba(65, 150, 68, 1)",
		},
		clear: {
			color: "rgba(245, 84, 44, 1)",
		},
		popup: {
			width: "100%",
			border: "1px solid green",
			left: 0,
		},
		modal: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)
