import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Typography from "@material-ui/core/Typography"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import clsx from "clsx"
import moment from "moment"
import * as React from "react"
import { useState } from "react"
import { formatCurrency, formatNumber } from "../../lib/formatters"
import type { SortDirection } from "../../sort"
import { theme } from "../../styles"
import {
	type UWLTableColumn,
	type UWLTableColumnDisplay,
	sortedRows,
	toVisibleColumnsInOrder,
} from "../../types/UWLTable"
import ErrorMessage from "../ErrorMessage"
import LoadingProgress from "../LoadingProgress"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listPage: {
			"& > thead > tr > th": {
				backgroundColor: "#3980b9",
				borderBottomWidth: 0,
				color: "#fff",
				paddingTop: theme.spacing(1) + "px !important",
			},
			"& > tbody > tr > td": {
				backgroundColor: "#eee",
				borderBottomWidth: theme.spacing(1),
				borderBottomColor: theme.palette.background.default,
			},
			"& > tbody > tr:hover > td": {
				backgroundColor: "#ddd",
			},
			"& > tbody > tr > td$rowExpandCell": {
				backgroundColor: theme.palette.background.paper,
			},
			"& > tbody$statusSection > tr > td": {
				backgroundColor: theme.palette.background.default,
			},
		},
		statusSection: {},
		rowExpandCell: {},
		theExpandedRow: {},
		root: {
			"& > tbody > tr$theExpandedRow > td": {
				border: "none",
			},
		},
		renderCell: {
			padding: theme.spacing(1, 2),
		},
		styledTableCell: {
			head: {
				...theme.typography.h3,
				textTransform: "uppercase",
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.text.secondary,
				padding: theme.spacing(1, 2),
			},
			sizeSmall: {
				padding: theme.spacing(1, 0.5),
			},
			renderCell: {
				padding: theme.spacing(1, 2),
			},
		},
	}),
)

function toggleChecked(ids: string[], id: string): string[] {
	if (ids.includes(id)) {
		return ids.filter((i) => i !== id)
	} else {
		return ids.concat([id])
	}
}

function colAlign(col: UWLTableColumn<any>): "left" | "right" {
	switch (col.type) {
		case "currency":
		case "weight":
		case "volume":
		case "number":
			return "right"
	}
	return "left"
}

function DefaultCellRender<T extends {}>(props: { column: UWLTableColumn<T>; row: T }) {
	const { row, column } = props

	switch (column.type) {
		case "weight":
			return (
				<Typography variant="body1">
					{formatNumber((row as any)[column.id])}{" "}
					{column.unitField && (row as any)[column.unitField]}
				</Typography>
			)

		case "volume":
			return (
				<Typography variant="body1">
					{formatNumber((row as any)[column.id])}{" "}
					{column.unitField && (row as any)[column.unitField]}
				</Typography>
			)

		case "currency":
			return (
				<Typography variant="body1">
					{formatCurrency((row as any)[column.id])}{" "}
					{column.currencyField && (row as any)[column.currencyField]}
				</Typography>
			)

		case "number":
			return <Typography variant="body1">{formatNumber((row as any)[column.id])}</Typography>

		case "date": {
			const m = moment((row as any)[column.id])
			return <Typography variant="body1">{m.format("M/DD/YYYY")}</Typography>
		}

		case "icon": {
			return <div>Hi im an icon</div>
		}
	}

	return <Typography variant="body1">{(row as any)[column.id] + ""}</Typography>
}

interface Props<T extends {}> {
	rowId: Extract<keyof T, string>
	columns: UWLTableColumn<T>[]
	rows: T[]
	columnsDisplay?: UWLTableColumnDisplay<T>[]
	onRowClick?: (row: T) => void
	isLoading?: boolean
	error?: string | null
	emptyMessage?: string | (() => React.ReactNode)
	rowAction?: (row: T) => React.ReactNode
	rowExpand?: (row: T) => React.ReactNode
	renderCellExpand?: (row: T) => React.ReactNode
	additionalRowExpandIDs?: string[]
	sizeMedium?: boolean
	listPage?: boolean
	paginateLimit?: number
	renderCell?: Partial<{
		[key in keyof T]: (row: T) => React.ReactNode
	}>
	checkboxes?: {
		checked: string[]
		setChecked(ids: string[]): void
	}
	disabled?: boolean

	onSortStateChange?: (sortState: {
		orderBy: keyof T | null
		sortDirection: SortDirection
	}) => void
}

export function UWLTable<T extends {}>(props: Props<T>) {
	const classes = useStyles()
	const [orderBy, setOrderBy] = useState<keyof T | null>(null)
	const [sortDirection, setSortDirection] = useState<SortDirection>("desc")
	const [expandedRowIDs, setExpandedRowIDs] = useState<string[]>([])

	const disabled = !!props.disabled
	const columns = toVisibleColumnsInOrder(props.columns, props.columnsDisplay)
	const rowsSorted = sortedRows(props.columns, props.rows, orderBy, sortDirection)
	let rows = rowsSorted
	if (props.paginateLimit && props.paginateLimit > 0) {
		rows = rowsSorted.slice(0, props.paginateLimit)
	}

	return (
		<>
			<Table
				stickyHeader
				size={props.listPage || props.sizeMedium ? "medium" : "small"}
				className={clsx({
					[classes.root]: true,
					[classes.listPage]: props.listPage,
				})}>
				<TableHead>
					<TableRow>
						{props.checkboxes && (
							<TableCell className={classes.styledTableCell} variant="head" padding="checkbox">
								<Checkbox
									indeterminate={
										props.checkboxes.checked.length > 0 &&
										props.checkboxes.checked.length < rows.length
									}
									checked={props.checkboxes.checked.length === rows.length}
									onChange={() => {
										if (!props.checkboxes) return
										if (props.checkboxes.checked.length === rows.length) {
											props.checkboxes.setChecked([])
										} else {
											props.checkboxes.setChecked(rows.map((row) => row[props.rowId] + ""))
										}
									}}
									inputProps={{ "aria-label": "select all" }}
									disabled={disabled}
								/>
							</TableCell>
						)}
						{columns.map((col) => {
							return (
								<TableCell
									className={classes.styledTableCell}
									key={col.id}
									variant="head"
									align={colAlign(col)}
									sortDirection={orderBy === col.id ? sortDirection : false}>
									<TableSortLabel
										active={orderBy === col.id}
										direction={sortDirection}
										onClick={() => {
											if (disabled) return
											if (orderBy === col.id) {
												setSortDirection(sortDirection === "asc" ? "desc" : "asc")
											} else {
												setOrderBy(col.id)
												setSortDirection("desc")
											}
										}}
										disabled={disabled}
										title={col.labelTitle}>
										{col.type === "icon" ? (
											<Box
												boxShadow="-2px 1px 5px -1px #00000029"
												margin={theme.spacing(-1, 0)}
												padding={1}>
												<i className="material-icons">{col.label}</i>
											</Box>
										) : (
											col.label
										)}
									</TableSortLabel>
								</TableCell>
							)
						})}
						{props.rowAction && <TableCell className={classes.styledTableCell} variant="head" />}
						{props.rowExpand && <TableCell className={classes.styledTableCell} variant="head" />}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => {
						const id = row[props.rowId] + ""
						const isExpanded =
							(props.rowExpand && expandedRowIDs.includes(id)) ||
							(props.renderCellExpand &&
								props.additionalRowExpandIDs &&
								props.additionalRowExpandIDs.includes(id))

						function toggleExpandedRow() {
							if (expandedRowIDs.includes(id)) {
								setExpandedRowIDs(expandedRowIDs.filter((i) => i !== id))
							} else {
								setExpandedRowIDs(expandedRowIDs.concat([id]))
							}
						}

						return (
							<React.Fragment key={id}>
								<TableRow
									hover={!isExpanded}
									onClick={() => {
										if (disabled) return
										if (props.onRowClick) {
											props.onRowClick(row)
										} else if (props.rowExpand || props.renderCellExpand) {
											toggleExpandedRow()
										} else if (props.checkboxes) {
											props.checkboxes.setChecked(
												toggleChecked(props.checkboxes.checked, row[props.rowId] + ""),
											)
										}
									}}
									className={isExpanded ? classes.theExpandedRow : undefined}>
									{props.checkboxes && (
										<TableCell className={classes.styledTableCell} padding="checkbox">
											<Checkbox
												checked={props.checkboxes.checked.includes(row[props.rowId] + "")}
												onChange={() => {
													if (!props.checkboxes) return
													props.checkboxes.setChecked(
														toggleChecked(props.checkboxes.checked, row[props.rowId] + ""),
													)
												}}
												disabled={disabled}
											/>
										</TableCell>
									)}
									{columns.map((col) => {
										const renderCell = props.renderCell && props.renderCell[col.id]

										return (
											<TableCell
												className={classes.styledTableCell}
												key={col.id}
												scope={col.id === props.rowId ? "row" : void 0}
												align={colAlign(col)}>
												{renderCell ? (
													renderCell(row)
												) : (
													<DefaultCellRender column={col} row={row} />
												)}
											</TableCell>
										)
									})}
									{props.rowAction && (
										<TableCell
											className={classes.styledTableCell}
											onClick={(e) => e.stopPropagation()}>
											{props.rowAction(row)}
										</TableCell>
									)}
									{props.rowExpand && (
										<TableCell className={classes.styledTableCell} onClick={toggleExpandedRow}>
											{expandedRowIDs.includes(id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
										</TableCell>
									)}
								</TableRow>
								{props.rowExpand && isExpanded && (
									<TableRow>
										<TableCell
											className={classes.rowExpandCell}
											colSpan={columns.length + (props.rowAction ? 1 : 0) + 1}>
											{props.rowExpand(row)}
										</TableCell>
									</TableRow>
								)}
								{props.renderCellExpand && isExpanded && (
									<TableRow>
										<TableCell
											className={classes.rowExpandCell}
											colSpan={columns.length + (props.rowAction ? 1 : 0) + 1}>
											{props.renderCellExpand(row)}
										</TableCell>
									</TableRow>
								)}
							</React.Fragment>
						)
					})}
				</TableBody>
			</Table>
			{props.isLoading && rows.length === 0 && <LoadingProgress />}
			{!props.isLoading && rows.length === 0 && (
				<Box padding={2}>
					{props.error ? (
						<ErrorMessage error={props.error} />
					) : props.emptyMessage ? (
						typeof props.emptyMessage === "string" ? (
							<Typography>{props.emptyMessage}</Typography>
						) : (
							props.emptyMessage()
						)
					) : (
						<Typography>- no data -</Typography>
					)}
				</Box>
			)}
		</>
	)
}
