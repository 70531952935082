import DateFnsUtils from "@date-io/date-fns"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Local from "date-fns/locale/en-US"
import type { Moment } from "moment"
import React from "react"
import {
	addCustomerRule,
	getCannedResponseList,
	getCustomerList,
	getCustomerRule,
	getIepList,
	getPoolList,
	getSslList,
	getTerminals,
	updateCustomerRule,
} from "../api"
import AddCannedResponse from "../components/AddCannedResponse"
import WaitPopup from "./WaitPopup"

const moment = require("moment")
interface Props {
	id: number
	handleClose: Function
	newState: boolean
}

export default function (props: Props) {
	const [customerRule, setCustomerRule] = React.useState<any | undefined>("")
	const [sslName, setSslName] = React.useState<any | undefined>("")
	const [sslCode, setSslCode] = React.useState<any | undefined>(-1)
	const [ssls, setSsls] = React.useState<any | undefined>([])
	const [customerName, setCustomerName] = React.useState(-1)
	const [customers, setCustomers] = React.useState<any | undefined>([])
	const [terminalCode, setTerminalCode] = React.useState(-1)
	const [terminals, setTerminals] = React.useState<any | undefined>([])
	const [poolCode, setPoolCode] = React.useState(-1)
	const [pools, setPools] = React.useState<any | undefined>([])
	const [iepCode, setIepCode] = React.useState(-1)
	const [ieps, setIeps] = React.useState<any | undefined>([])
	const [cannedResponse, setCannedResponse] = React.useState(-1)
	const [cannedResponses, setCannedResponses] = React.useState<any | undefined>([])
	const [consignee, setConsignee] = React.useState()
	const [importExport, setImportExport] = React.useState()
	const [forTerminalNam, setForTerminalNam] = React.useState()
	const [deliveryLocation, setDeliveryLocation] = React.useState()
	const [open, setOpen] = React.useState(false)
	const [endDate, setEndDate] = React.useState<Moment | null>(moment())
	const [ecmComments, setEcmComments] = React.useState()
	const [contractNumber, setContractNumber] = React.useState()

	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (customerRule) {
			setSslName(customerRule.ssl_name)
			setSslCode(customerRule.ssl_id)
			setTerminalCode(customerRule.terminal_id)
			setCustomerName(customerRule.customer_bill_to_id)
			setCannedResponse(customerRule.canned_response_id)
			setPoolCode(customerRule.pool_id)
			setIepCode(customerRule.billing_party_id)
			setConsignee(customerRule.consignee)
			setImportExport(customerRule.import_export)
			setForTerminalNam(customerRule.for_terminal_nam)
			setDeliveryLocation(customerRule.delivery_location)
			if (customerRule.end_date) {
				customerRule.end_date = moment.utc(customerRule.end_date.replace("GMT", ""))
				console.log("DATE:CUST:", customerRule.end_date)
			}
			setEndDate(customerRule.end_date)
			setEcmComments(customerRule.ecm_comments)
			setContractNumber(customerRule.contract_num)
		}
	}, [customerRule])

	function loadPageInfo() {
		if (props.newState) {
			setCustomerRule({
				sslName: "",
				sslCode: "",
				contact: "",
				iepCode: "",
				terminalCode: "",
				customerName: "",
				cannedResponse: "",
				poolCode: "",
				consignee: "",
				importExport: "",
				deliveryLocation: "",
				ecmComments: "",
				forTerminalNam: "",
				contractNumber: "",
			})
		} else {
			getCustomerRule(props.id, setCustomerRule)
		}
		getTerminals(setTerminals)
		getSslList(setSsls)
		getPoolList(setPools)
		getIepList(setIeps)
		getCustomerList(setCustomers)
		getCannedResponseList(setCannedResponses)
	}

	function handleSSLDDChange(event: any) {
		setSslCode(event.target.value)
		setSslName(ssls.find((s) => s.id_ == event.target.value).ssl_name)
	}

	function handleTerminalDDChange(event: any) {
		setTerminalCode(event.target.value)
	}

	function handlePoolDDChange(event: any) {
		setPoolCode(event.target.value)
	}

	function handleIepDDChange(event: any) {
		setIepCode(event.target.value)
	}

	function handleCustomerDDChange(event: any) {
		setCustomerName(event.target.value)
	}

	function handleCannedResponseDDChange(event: any) {
		setCannedResponse(event.target.value)
	}

	const handleEndDateChange = (date: Moment | null) => {
		setEndDate(date)
	}

	const handleNewClick = (event: any) => {
		setOpen(true)
	}

	function handleSave() {
		if (validated()) {
			if (props.newState) {
				customerRule.ssl_id = sslCode
				customerRule.terminal_id = terminalCode
				customerRule.pool_id = poolCode
				customerRule.billing_party_id = iepCode
				customerRule.consignee = consignee
				customerRule.customer_bill_to_id = customerName
				customerRule.canned_response_id = cannedResponse
				customerRule.delivery_location = deliveryLocation
				customerRule.ecm_comments = ecmComments
				customerRule.end_date = endDate
				customerRule.for_terminal_nam = forTerminalNam
				customerRule.import_export = importExport
				customerRule.contract_num = contractNumber
				addCustomerRule(customerRule, props.handleClose)
			} else {
				customerRule.ssl_id = sslCode
				customerRule.terminal_id = terminalCode
				customerRule.pool_id = poolCode
				customerRule.billing_party_id = iepCode
				customerRule.consignee = consignee
				customerRule.customer_bill_to_ = customerName
				customerRule.canned_response_reason = cannedResponse
				customerRule.delivery_location = deliveryLocation
				customerRule.ecm_comments = ecmComments
				customerRule.end_date = endDate
				customerRule.for_terminal_nam = forTerminalNam
				customerRule.import_export = importExport
				customerRule.contract_num = contractNumber
				updateCustomerRule(customerRule, props.handleClose)
			}
		} else {
			alert("Information is incomplete or not in the correct format.")
		}
	}

	function rand() {
		return Math.round(Math.random() * 15) - 10
	}

	function getModalStyle() {
		return {
			top: `${top}%`,
			margin: "auto",
		}
	}

	function validated() {
		if (props.newState) {
			if (customerName) {
				return true
			} else {
				return false
			}
		}
		return true
	}

	if (customerRule) {
		openWait = false
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		loadPageInfo()
		setOpen(false)
	}
	return (
		<div>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={open}
				style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				onClose={handleClose}>
				<div style={{ minWidth: 500 }}>
					<AddCannedResponse id={0} handleClose={handleClose} newState={true} />
				</div>
			</Modal>
			<Grid container>
				<Grid item sm={12}>
					<Box>{props.newState ? <h3>Add Rule</h3> : <h3>Edit Rule</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								select
								variant="outlined"
								label="TERMINAL"
								fullWidth
								value={terminalCode}
								InputLabelProps={{ shrink: true }}
								onChange={handleTerminalDDChange}>
								{terminals &&
									terminals.map((item, index) => {
										if (terminals.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.abbrv}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								variant="outlined"
								label="CUSTOMER"
								value={customerName}
								select
								fullWidth
								SelectProps={{ displayEmpty: true }}
								InputLabelProps={{ shrink: true }}
								onChange={handleCustomerDDChange}>
								{customers &&
									customers.map((item, index) => {
										if (customers.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.name}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								variant="outlined"
								label="SSL CODE"
								value={sslCode}
								select
								fullWidth
								SelectProps={{ displayEmpty: true }}
								InputLabelProps={{ shrink: true }}
								onChange={handleSSLDDChange}>
								{ssls &&
									ssls.map((item, index) => {
										if (ssls.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.abbrv}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								select
								fullWidth
								variant="outlined"
								label="POOL"
								value={poolCode}
								SelectProps={{ displayEmpty: true }}
								InputLabelProps={{ shrink: true }}
								onChange={handlePoolDDChange}>
								{pools &&
									pools.map((item, index) => {
										if (pools.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.pool_code}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								select
								fullWidth
								variant="outlined"
								label="BILLING PARTY"
								value={iepCode}
								SelectProps={{ displayEmpty: true }}
								InputLabelProps={{ shrink: true }}
								onChange={handleIepDDChange}>
								{ieps &&
									ieps.map((item, index) => {
										if (ieps.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.abbrv}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="CONSIGNEE"
								value={consignee}
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(event: any) => {
									setConsignee(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="IMPORT/EXPORT"
								value={importExport}
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(event: any) => {
									setImportExport(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="DELIVERY LOCATION"
								value={deliveryLocation}
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(event: any) => {
									setDeliveryLocation(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="CONTRACT NUMBER"
								value={contractNumber}
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(event: any) => {
									setContractNumber(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={12} style={{ margin: 10 }}>
							<TextField
								label="FOR TERMINAL/NAM"
								value={forTerminalNam}
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(event: any) => {
									setForTerminalNam(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={12} style={{ margin: 10 }}>
							<TextField
								label="ECM COMMENTS"
								value={ecmComments}
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(event: any) => {
									setEcmComments(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={10} style={{ margin: 10 }}>
							<TextField
								select
								fullWidth
								variant="outlined"
								label="CANNED RESPONSE"
								value={cannedResponse}
								SelectProps={{ displayEmpty: true }}
								InputLabelProps={{ shrink: true }}
								onChange={handleCannedResponseDDChange}>
								{cannedResponses &&
									cannedResponses.map((item, index) => {
										if (ieps.length <= 0) {
											return <div />
										}
										return (
											<MenuItem key={index} value={item.id_}>
												{item.reason}
											</MenuItem>
										)
									})}
							</TextField>
						</Grid>
						<Grid item sm={1} style={{ margin: 10 }}>
							<Button
								variant="outlined"
								color="primary"
								disableElevation
								onClick={(event) => handleNewClick(event)}
								style={{ height: "100%" }}>
								<AddIcon />
							</Button>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={Local}>
								<KeyboardDatePicker
									disableToolbar
									inputVariant="outlined"
									variant="inline"
									format="MM/dd/yyyy"
									margin="normal"
									error={false}
									id="date-picker-inline"
									label="END DATE"
									value={endDate}
									style={{ width: "100%" }}
									onChange={handleEndDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										props.handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary">
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
