export interface DataCustomerManager {
	id_: number
	reason: string
}

export const HeaderDataCustomerManager: HeadCell[] = [
	{
		id: "reason",
		numeric: false,
		disablePadding: false,
		label: "NAME",
	},
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataCustomerManager
	label: string
	numeric: boolean
}
